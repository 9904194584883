/* tslint:disable */
/* eslint-disable */
/**
 * BeeftekaBackend.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddToBasketDto
 */
export interface AddToBasketDto {
    /**
     * 
     * @type {number}
     * @memberof AddToBasketDto
     */
    'productPriceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddToBasketDto
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface AddressCreateDto
 */
export interface AddressCreateDto {
    /**
     * 
     * @type {number}
     * @memberof AddressCreateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressCreateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressCreateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressCreateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddressCreateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddressCreateDto
     */
    'applicationUserId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddressCreateDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressCreateDto
     */
    'buildingNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressCreateDto
     */
    'flatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressCreateDto
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressCreateDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressCreateDto
     */
    'vatId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressCreateDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressCreateDto
     */
    'orderGuid'?: string | null;
}
/**
 * 
 * @export
 * @interface AddressDetailsDto
 */
export interface AddressDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof AddressDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddressDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddressDetailsDto
     */
    'applicationUserId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailsDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailsDto
     */
    'buildingNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailsDto
     */
    'flatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailsDto
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailsDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailsDto
     */
    'vatId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailsDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailsDto
     */
    'orderGuid'?: string | null;
}
/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * 
     * @type {number}
     * @memberof AddressDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddressDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddressDto
     */
    'applicationUserId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'buildingNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'flatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'vatId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'orderGuid'?: string | null;
}
/**
 * 
 * @export
 * @interface AddressUpdateDto
 */
export interface AddressUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof AddressUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddressUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddressUpdateDto
     */
    'applicationUserId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdateDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdateDto
     */
    'buildingNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdateDto
     */
    'flatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdateDto
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdateDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdateDto
     */
    'vatId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdateDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdateDto
     */
    'orderGuid'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserActivationDto
 */
export interface ApplicationUserActivationDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    'token'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserActivationDto
     */
    'acceptedTermsAndConditions'?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationUserChangePasswordDto
 */
export interface ApplicationUserChangePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserChangePasswordDto
     */
    'currentPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserChangePasswordDto
     */
    'newPassword'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserDetailsDto
 */
export interface ApplicationUserDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsDto
     */
    'emailConfirmed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsDto
     */
    'newsletter'?: boolean;
    /**
     * 
     * @type {Array<AddressDto>}
     * @memberof ApplicationUserDetailsDto
     */
    'addresses'?: Array<AddressDto> | null;
    /**
     * 
     * @type {Array<OrderDto>}
     * @memberof ApplicationUserDetailsDto
     */
    'orders'?: Array<OrderDto> | null;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ApplicationUserDetailsDto
     */
    'roles'?: Array<ApplicationUserRole> | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserDto
 */
export interface ApplicationUserDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDto
     */
    'newsletter'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDto
     */
    'emailConfirmed'?: boolean;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ApplicationUserDto
     */
    'roles'?: Array<ApplicationUserRole> | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserRegisterDto
 */
export interface ApplicationUserRegisterDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    'emailConfirmed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    'password'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    'withInvitation'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    'newsletter'?: boolean;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ApplicationUserRegisterDto
     */
    'roles'?: Array<ApplicationUserRole> | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    'turnstileToken'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ApplicationUserRole = {
    Admin: 'Admin',
    MenuManager: 'MenuManager',
    NewsManager: 'NewsManager',
    BeerManager: 'BeerManager',
    ProductManager: 'ProductManager',
    OrderManager: 'OrderManager'
} as const;

export type ApplicationUserRole = typeof ApplicationUserRole[keyof typeof ApplicationUserRole];


/**
 * 
 * @export
 * @interface BasketDto
 */
export interface BasketDto {
    /**
     * 
     * @type {string}
     * @memberof BasketDto
     */
    'id'?: string;
    /**
     * 
     * @type {Array<BasketItemDto>}
     * @memberof BasketDto
     */
    'items'?: Array<BasketItemDto> | null;
    /**
     * 
     * @type {string}
     * @memberof BasketDto
     */
    'readDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof BasketDto
     */
    'discountCodeId'?: number | null;
    /**
     * 
     * @type {DiscountCodeDto}
     * @memberof BasketDto
     */
    'discountCode'?: DiscountCodeDto;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof BasketDto
     */
    'user'?: ApplicationUserDto;
}
/**
 * 
 * @export
 * @interface BasketItemDto
 */
export interface BasketItemDto {
    /**
     * 
     * @type {string}
     * @memberof BasketItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof BasketItemDto
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof BasketItemDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BasketItemDto
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof BasketItemDto
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BasketItemDto
     */
    'productPriceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BasketItemDto
     */
    'unit'?: string | null;
}
/**
 * 
 * @export
 * @interface BeerCreateDto
 */
export interface BeerCreateDto {
    /**
     * 
     * @type {number}
     * @memberof BeerCreateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BeerCreateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof BeerCreateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof BeerCreateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeerCreateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BeerCreateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeerCreateDto
     */
    'position'?: number;
    /**
     * 
     * @type {string}
     * @memberof BeerCreateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeerCreateDto
     */
    'beerImageId'?: number;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof BeerCreateDto
     */
    'beerImage'?: FileInfoDto;
}
/**
 * 
 * @export
 * @interface BeerDetailsDto
 */
export interface BeerDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof BeerDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BeerDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof BeerDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof BeerDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeerDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BeerDetailsDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeerDetailsDto
     */
    'position'?: number;
    /**
     * 
     * @type {string}
     * @memberof BeerDetailsDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeerDetailsDto
     */
    'beerImageId'?: number;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof BeerDetailsDto
     */
    'beerImage'?: FileInfoDto;
    /**
     * 
     * @type {string}
     * @memberof BeerDetailsDto
     */
    'imageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface BeerDto
 */
export interface BeerDto {
    /**
     * 
     * @type {number}
     * @memberof BeerDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BeerDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof BeerDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof BeerDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeerDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BeerDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeerDto
     */
    'position'?: number;
    /**
     * 
     * @type {string}
     * @memberof BeerDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeerDto
     */
    'beerImageId'?: number;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof BeerDto
     */
    'beerImage'?: FileInfoDto;
}
/**
 * 
 * @export
 * @interface BeerUpdateDto
 */
export interface BeerUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof BeerUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BeerUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof BeerUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof BeerUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeerUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BeerUpdateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeerUpdateDto
     */
    'position'?: number;
    /**
     * 
     * @type {string}
     * @memberof BeerUpdateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeerUpdateDto
     */
    'beerImageId'?: number;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof BeerUpdateDto
     */
    'beerImage'?: FileInfoDto;
}
/**
 * 
 * @export
 * @interface CategoryCreateDto
 */
export interface CategoryCreateDto {
    /**
     * 
     * @type {number}
     * @memberof CategoryCreateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryCreateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryCreateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryCreateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryCreateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryCreateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryCreateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryCreateDto
     */
    'parentCategoryId'?: number | null;
    /**
     * 
     * @type {Array<CategoryProductsMinimal>}
     * @memberof CategoryCreateDto
     */
    'products'?: Array<CategoryProductsMinimal> | null;
}
/**
 * 
 * @export
 * @interface CategoryDetailsDto
 */
export interface CategoryDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof CategoryDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryDetailsDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryDetailsDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryDetailsDto
     */
    'parentCategoryId'?: number | null;
    /**
     * 
     * @type {Array<CategoryProductsMinimal>}
     * @memberof CategoryDetailsDto
     */
    'products'?: Array<CategoryProductsMinimal> | null;
    /**
     * 
     * @type {CategoryDto}
     * @memberof CategoryDetailsDto
     */
    'parentCategory'?: CategoryDto;
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof CategoryDetailsDto
     */
    'categories'?: Array<CategoryDto> | null;
}
/**
 * 
 * @export
 * @interface CategoryDto
 */
export interface CategoryDto {
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    'parentCategoryId'?: number | null;
    /**
     * 
     * @type {Array<CategoryProductsMinimal>}
     * @memberof CategoryDto
     */
    'products'?: Array<CategoryProductsMinimal> | null;
}
/**
 * 
 * @export
 * @interface CategoryProductDto
 */
export interface CategoryProductDto {
    /**
     * 
     * @type {number}
     * @memberof CategoryProductDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryProductDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryProductDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryProductDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryProductDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryProductDto
     */
    'categoryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoryProductDto
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoryProductDto
     */
    'productPosition'?: number;
    /**
     * 
     * @type {CategoryDto}
     * @memberof CategoryProductDto
     */
    'category'?: CategoryDto;
    /**
     * 
     * @type {ProductDto}
     * @memberof CategoryProductDto
     */
    'product'?: ProductDto;
}
/**
 * 
 * @export
 * @interface CategoryProductsMinimal
 */
export interface CategoryProductsMinimal {
    /**
     * 
     * @type {number}
     * @memberof CategoryProductsMinimal
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryProductsMinimal
     */
    'productName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryProductsMinimal
     */
    'productPosition'?: number;
}
/**
 * 
 * @export
 * @interface CategoryUpdateDto
 */
export interface CategoryUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof CategoryUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryUpdateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryUpdateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryUpdateDto
     */
    'parentCategoryId'?: number | null;
    /**
     * 
     * @type {Array<CategoryProductsMinimal>}
     * @memberof CategoryUpdateDto
     */
    'products'?: Array<CategoryProductsMinimal> | null;
}
/**
 * 
 * @export
 * @interface CloudflarePagesDeployment
 */
export interface CloudflarePagesDeployment {
    /**
     * 
     * @type {string}
     * @memberof CloudflarePagesDeployment
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudflarePagesDeployment
     */
    'project_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudflarePagesDeployment
     */
    'created_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudflarePagesDeployment
     */
    'modified_on'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CloudflarePagesDeployment
     */
    'project_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CloudflarePagesDeployment
     */
    'environment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CloudflarePagesDeployment
     */
    'url'?: string | null;
    /**
     * 
     * @type {Array<CloudflarePagesDeploymentStage>}
     * @memberof CloudflarePagesDeployment
     */
    'stages'?: Array<CloudflarePagesDeploymentStage> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CloudflarePagesDeployment
     */
    'is_skipped'?: boolean;
}
/**
 * 
 * @export
 * @interface CloudflarePagesDeploymentStage
 */
export interface CloudflarePagesDeploymentStage {
    /**
     * 
     * @type {string}
     * @memberof CloudflarePagesDeploymentStage
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CloudflarePagesDeploymentStage
     */
    'started_on'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CloudflarePagesDeploymentStage
     */
    'ended_on'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CloudflarePagesDeploymentStage
     */
    'status'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DeliveryMethod = {
    SelfPickup: 'SelfPickup',
    InPostParcelMachine: 'InPostParcelMachine',
    InPostCarrier: 'InPostCarrier'
} as const;

export type DeliveryMethod = typeof DeliveryMethod[keyof typeof DeliveryMethod];


/**
 * 
 * @export
 * @interface DeployPageDto
 */
export interface DeployPageDto {
    /**
     * 
     * @type {string}
     * @memberof DeployPageDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeployPageDto
     */
    'domains'?: Array<string> | null;
    /**
     * 
     * @type {CloudflarePagesDeployment}
     * @memberof DeployPageDto
     */
    'latestDeployment'?: CloudflarePagesDeployment;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Direction = {
    Up: 'Up',
    Down: 'Down'
} as const;

export type Direction = typeof Direction[keyof typeof Direction];


/**
 * 
 * @export
 * @interface DiscountCodeCreateDto
 */
export interface DiscountCodeCreateDto {
    /**
     * 
     * @type {number}
     * @memberof DiscountCodeCreateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodeCreateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodeCreateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodeCreateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodeCreateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodeCreateDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodeCreateDto
     */
    'expirationDate'?: string | null;
    /**
     * 
     * @type {DiscountType}
     * @memberof DiscountCodeCreateDto
     */
    'discountType'?: DiscountType;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodeCreateDto
     */
    'discountValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodeCreateDto
     */
    'minimumOrderAmount'?: number | null;
}
/**
 * 
 * @export
 * @interface DiscountCodeDetailsDto
 */
export interface DiscountCodeDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof DiscountCodeDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodeDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodeDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodeDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodeDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodeDetailsDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodeDetailsDto
     */
    'expirationDate'?: string | null;
    /**
     * 
     * @type {DiscountType}
     * @memberof DiscountCodeDetailsDto
     */
    'discountType'?: DiscountType;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodeDetailsDto
     */
    'discountValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodeDetailsDto
     */
    'minimumOrderAmount'?: number | null;
    /**
     * 
     * @type {Array<OrderDto>}
     * @memberof DiscountCodeDetailsDto
     */
    'order'?: Array<OrderDto> | null;
}
/**
 * 
 * @export
 * @interface DiscountCodeDto
 */
export interface DiscountCodeDto {
    /**
     * 
     * @type {number}
     * @memberof DiscountCodeDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodeDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodeDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodeDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodeDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodeDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodeDto
     */
    'expirationDate'?: string | null;
    /**
     * 
     * @type {DiscountType}
     * @memberof DiscountCodeDto
     */
    'discountType'?: DiscountType;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodeDto
     */
    'discountValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodeDto
     */
    'minimumOrderAmount'?: number | null;
}
/**
 * 
 * @export
 * @interface DiscountCodeResponseDto
 */
export interface DiscountCodeResponseDto {
    /**
     * 
     * @type {DiscountCodeValidationMessage}
     * @memberof DiscountCodeResponseDto
     */
    'responseMessage'?: DiscountCodeValidationMessage;
    /**
     * 
     * @type {DiscountCodeDto}
     * @memberof DiscountCodeResponseDto
     */
    'discountCode'?: DiscountCodeDto;
}
/**
 * 
 * @export
 * @interface DiscountCodeUpdateDto
 */
export interface DiscountCodeUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof DiscountCodeUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodeUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodeUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodeUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodeUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodeUpdateDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DiscountCodeUpdateDto
     */
    'expirationDate'?: string | null;
    /**
     * 
     * @type {DiscountType}
     * @memberof DiscountCodeUpdateDto
     */
    'discountType'?: DiscountType;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodeUpdateDto
     */
    'discountValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DiscountCodeUpdateDto
     */
    'minimumOrderAmount'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DiscountCodeValidationMessage = {
    Ok: 'Ok',
    CodeIncorrect: 'CodeIncorrect',
    OrderAmountNotReached: 'OrderAmountNotReached',
    CodeExpired: 'CodeExpired'
} as const;

export type DiscountCodeValidationMessage = typeof DiscountCodeValidationMessage[keyof typeof DiscountCodeValidationMessage];


/**
 * 
 * @export
 * @enum {string}
 */

export const DiscountType = {
    Quota: 'Quota',
    Percent: 'Percent',
    FreeDelivery: 'FreeDelivery'
} as const;

export type DiscountType = typeof DiscountType[keyof typeof DiscountType];


/**
 * 
 * @export
 * @interface EndForgotPasswordDto
 */
export interface EndForgotPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof EndForgotPasswordDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EndForgotPasswordDto
     */
    'token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EndForgotPasswordDto
     */
    'newPassword'?: string | null;
}
/**
 * 
 * @export
 * @interface FileDetailsDto
 */
export interface FileDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof FileDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FileDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FileDetailsDto
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileDetailsDto
     */
    'contentType'?: string | null;
}
/**
 * 
 * @export
 * @interface FileDto
 */
export interface FileDto {
    /**
     * 
     * @type {number}
     * @memberof FileDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FileDto
     */
    'updatedById'?: number | null;
}
/**
 * 
 * @export
 * @interface FileInfoDto
 */
export interface FileInfoDto {
    /**
     * 
     * @type {number}
     * @memberof FileInfoDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDto
     */
    'fileName'?: string | null;
}
/**
 * 
 * @export
 * @interface FileUpdateDto
 */
export interface FileUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof FileUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FileUpdateDto
     */
    'updatedById'?: number | null;
}
/**
 * 
 * @export
 * @interface InPostParcelMachineDetailsDto
 */
export interface InPostParcelMachineDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof InPostParcelMachineDetailsDto
     */
    'parcelLockerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InPostParcelMachineDetailsDto
     */
    'addressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InPostParcelMachineDetailsDto
     */
    'addressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InPostParcelMachineDetailsDto
     */
    'locationDescription'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Language = {
    Polish: 'Polish',
    English: 'English'
} as const;

export type Language = typeof Language[keyof typeof Language];


/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LoginDto
     */
    'rememberMe'?: boolean;
}
/**
 * 
 * @export
 * @interface MenuCategoryCreateDto
 */
export interface MenuCategoryCreateDto {
    /**
     * 
     * @type {number}
     * @memberof MenuCategoryCreateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuCategoryCreateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuCategoryCreateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuCategoryCreateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuCategoryCreateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MenuCategoryCreateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuCategoryCreateDto
     */
    'position'?: number;
}
/**
 * 
 * @export
 * @interface MenuCategoryDetailsDto
 */
export interface MenuCategoryDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof MenuCategoryDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuCategoryDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuCategoryDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuCategoryDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuCategoryDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MenuCategoryDetailsDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuCategoryDetailsDto
     */
    'position'?: number;
    /**
     * 
     * @type {Array<MenuEntryDto>}
     * @memberof MenuCategoryDetailsDto
     */
    'menuEntries'?: Array<MenuEntryDto> | null;
}
/**
 * 
 * @export
 * @interface MenuCategoryDto
 */
export interface MenuCategoryDto {
    /**
     * 
     * @type {number}
     * @memberof MenuCategoryDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuCategoryDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuCategoryDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuCategoryDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuCategoryDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MenuCategoryDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuCategoryDto
     */
    'position'?: number;
}
/**
 * 
 * @export
 * @interface MenuCategoryUpdateDto
 */
export interface MenuCategoryUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof MenuCategoryUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuCategoryUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuCategoryUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuCategoryUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuCategoryUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MenuCategoryUpdateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuCategoryUpdateDto
     */
    'position'?: number;
}
/**
 * 
 * @export
 * @interface MenuCreateDto
 */
export interface MenuCreateDto {
    /**
     * 
     * @type {number}
     * @memberof MenuCreateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuCreateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuCreateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuCreateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuCreateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof MenuCreateDto
     */
    'menuFile'?: FileInfoDto;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof MenuCreateDto
     */
    'englishMenuFile'?: FileInfoDto;
    /**
     * 
     * @type {number}
     * @memberof MenuCreateDto
     */
    'menuFileId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuCreateDto
     */
    'englishMenuFileId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuCreateDto
     */
    'menuFileUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MenuCreateDto
     */
    'englishMenuFileUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface MenuDetailsDto
 */
export interface MenuDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof MenuDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof MenuDetailsDto
     */
    'menuFile'?: FileInfoDto;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof MenuDetailsDto
     */
    'englishMenuFile'?: FileInfoDto;
    /**
     * 
     * @type {number}
     * @memberof MenuDetailsDto
     */
    'menuFileId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuDetailsDto
     */
    'englishMenuFileId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuDetailsDto
     */
    'menuFileUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MenuDetailsDto
     */
    'englishMenuFileUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface MenuDto
 */
export interface MenuDto {
    /**
     * 
     * @type {number}
     * @memberof MenuDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof MenuDto
     */
    'menuFile'?: FileInfoDto;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof MenuDto
     */
    'englishMenuFile'?: FileInfoDto;
    /**
     * 
     * @type {number}
     * @memberof MenuDto
     */
    'menuFileId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuDto
     */
    'englishMenuFileId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuDto
     */
    'menuFileUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MenuDto
     */
    'englishMenuFileUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface MenuEntryCreateDto
 */
export interface MenuEntryCreateDto {
    /**
     * 
     * @type {number}
     * @memberof MenuEntryCreateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuEntryCreateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuEntryCreateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuEntryCreateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuEntryCreateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MenuEntryCreateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MenuEntryCreateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuEntryCreateDto
     */
    'position'?: number;
    /**
     * 
     * @type {Array<PriceDto>}
     * @memberof MenuEntryCreateDto
     */
    'prices'?: Array<PriceDto> | null;
    /**
     * 
     * @type {number}
     * @memberof MenuEntryCreateDto
     */
    'menuCategoryId'?: number;
    /**
     * 
     * @type {MenuCategoryDto}
     * @memberof MenuEntryCreateDto
     */
    'menuCategory'?: MenuCategoryDto;
    /**
     * 
     * @type {boolean}
     * @memberof MenuEntryCreateDto
     */
    'isVegan'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuEntryCreateDto
     */
    'isGlutenFree'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuEntryCreateDto
     */
    'isGlutenFreeAvailable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuEntryCreateDto
     */
    'isDairyFree'?: boolean;
}
/**
 * 
 * @export
 * @interface MenuEntryDetailsDto
 */
export interface MenuEntryDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof MenuEntryDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuEntryDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuEntryDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuEntryDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuEntryDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MenuEntryDetailsDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MenuEntryDetailsDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuEntryDetailsDto
     */
    'position'?: number;
    /**
     * 
     * @type {Array<PriceDto>}
     * @memberof MenuEntryDetailsDto
     */
    'prices'?: Array<PriceDto> | null;
    /**
     * 
     * @type {number}
     * @memberof MenuEntryDetailsDto
     */
    'menuCategoryId'?: number;
    /**
     * 
     * @type {MenuCategoryDto}
     * @memberof MenuEntryDetailsDto
     */
    'menuCategory'?: MenuCategoryDto;
    /**
     * 
     * @type {boolean}
     * @memberof MenuEntryDetailsDto
     */
    'isVegan'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuEntryDetailsDto
     */
    'isGlutenFree'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuEntryDetailsDto
     */
    'isGlutenFreeAvailable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuEntryDetailsDto
     */
    'isDairyFree'?: boolean;
}
/**
 * 
 * @export
 * @interface MenuEntryDto
 */
export interface MenuEntryDto {
    /**
     * 
     * @type {number}
     * @memberof MenuEntryDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuEntryDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuEntryDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuEntryDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuEntryDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MenuEntryDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MenuEntryDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuEntryDto
     */
    'position'?: number;
    /**
     * 
     * @type {Array<PriceDto>}
     * @memberof MenuEntryDto
     */
    'prices'?: Array<PriceDto> | null;
    /**
     * 
     * @type {number}
     * @memberof MenuEntryDto
     */
    'menuCategoryId'?: number;
    /**
     * 
     * @type {MenuCategoryDto}
     * @memberof MenuEntryDto
     */
    'menuCategory'?: MenuCategoryDto;
    /**
     * 
     * @type {boolean}
     * @memberof MenuEntryDto
     */
    'isVegan'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuEntryDto
     */
    'isGlutenFree'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuEntryDto
     */
    'isGlutenFreeAvailable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuEntryDto
     */
    'isDairyFree'?: boolean;
}
/**
 * 
 * @export
 * @interface MenuEntryUpdateDto
 */
export interface MenuEntryUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof MenuEntryUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuEntryUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuEntryUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuEntryUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuEntryUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MenuEntryUpdateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MenuEntryUpdateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuEntryUpdateDto
     */
    'position'?: number;
    /**
     * 
     * @type {Array<PriceDto>}
     * @memberof MenuEntryUpdateDto
     */
    'prices'?: Array<PriceDto> | null;
    /**
     * 
     * @type {number}
     * @memberof MenuEntryUpdateDto
     */
    'menuCategoryId'?: number;
    /**
     * 
     * @type {MenuCategoryDto}
     * @memberof MenuEntryUpdateDto
     */
    'menuCategory'?: MenuCategoryDto;
    /**
     * 
     * @type {boolean}
     * @memberof MenuEntryUpdateDto
     */
    'isVegan'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuEntryUpdateDto
     */
    'isGlutenFree'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuEntryUpdateDto
     */
    'isGlutenFreeAvailable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuEntryUpdateDto
     */
    'isDairyFree'?: boolean;
}
/**
 * 
 * @export
 * @interface MenuUpdateDto
 */
export interface MenuUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof MenuUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof MenuUpdateDto
     */
    'menuFile'?: FileInfoDto;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof MenuUpdateDto
     */
    'englishMenuFile'?: FileInfoDto;
    /**
     * 
     * @type {number}
     * @memberof MenuUpdateDto
     */
    'menuFileId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuUpdateDto
     */
    'englishMenuFileId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuUpdateDto
     */
    'menuFileUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MenuUpdateDto
     */
    'englishMenuFileUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface NewsSectionCreateDto
 */
export interface NewsSectionCreateDto {
    /**
     * 
     * @type {number}
     * @memberof NewsSectionCreateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionCreateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewsSectionCreateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionCreateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsSectionCreateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionCreateDto
     */
    'newsTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionCreateDto
     */
    'newsUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionCreateDto
     */
    'newsDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionCreateDto
     */
    'eventTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionCreateDto
     */
    'eventUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionCreateDto
     */
    'eventDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsSectionCreateDto
     */
    'imageId'?: number;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof NewsSectionCreateDto
     */
    'image'?: FileInfoDto;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionCreateDto
     */
    'imageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface NewsSectionDetailsDto
 */
export interface NewsSectionDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof NewsSectionDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewsSectionDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsSectionDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionDetailsDto
     */
    'newsTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionDetailsDto
     */
    'newsUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionDetailsDto
     */
    'newsDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionDetailsDto
     */
    'eventTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionDetailsDto
     */
    'eventUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionDetailsDto
     */
    'eventDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsSectionDetailsDto
     */
    'imageId'?: number;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof NewsSectionDetailsDto
     */
    'image'?: FileInfoDto;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionDetailsDto
     */
    'imageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface NewsSectionDto
 */
export interface NewsSectionDto {
    /**
     * 
     * @type {number}
     * @memberof NewsSectionDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewsSectionDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsSectionDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionDto
     */
    'newsTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionDto
     */
    'newsUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionDto
     */
    'newsDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionDto
     */
    'eventTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionDto
     */
    'eventUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionDto
     */
    'eventDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsSectionDto
     */
    'imageId'?: number;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof NewsSectionDto
     */
    'image'?: FileInfoDto;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionDto
     */
    'imageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface NewsSectionUpdateDto
 */
export interface NewsSectionUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof NewsSectionUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewsSectionUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsSectionUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionUpdateDto
     */
    'newsTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionUpdateDto
     */
    'newsUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionUpdateDto
     */
    'newsDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionUpdateDto
     */
    'eventTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionUpdateDto
     */
    'eventUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionUpdateDto
     */
    'eventDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsSectionUpdateDto
     */
    'imageId'?: number;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof NewsSectionUpdateDto
     */
    'image'?: FileInfoDto;
    /**
     * 
     * @type {string}
     * @memberof NewsSectionUpdateDto
     */
    'imageUrl'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OperationType = {
    Add: 'Add',
    Remove: 'Remove',
    Replace: 'Replace',
    Move: 'Move',
    Copy: 'Copy',
    Test: 'Test',
    Invalid: 'Invalid'
} as const;

export type OperationType = typeof OperationType[keyof typeof OperationType];


/**
 * 
 * @export
 * @interface OrderCreateDto
 */
export interface OrderCreateDto {
    /**
     * 
     * @type {number}
     * @memberof OrderCreateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateDto
     */
    'orderGuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateDto
     */
    'orderNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateDto
     */
    'clientId'?: number | null;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof OrderCreateDto
     */
    'client'?: ApplicationUserDto;
    /**
     * 
     * @type {UnregisteredUserDataDto}
     * @memberof OrderCreateDto
     */
    'unregisteredUserData'?: UnregisteredUserDataDto;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateDto
     */
    'optionalMessage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateDto
     */
    'deliveryAddressId'?: number | null;
    /**
     * 
     * @type {AddressDto}
     * @memberof OrderCreateDto
     */
    'deliveryAddress'?: AddressDto;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCreateDto
     */
    'deliveryAddressSameAsBillingAddress'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateDto
     */
    'billingAddressId'?: number | null;
    /**
     * 
     * @type {AddressDto}
     * @memberof OrderCreateDto
     */
    'billingAddress'?: AddressDto;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateDto
     */
    'orderValue'?: number;
    /**
     * 
     * @type {DeliveryMethod}
     * @memberof OrderCreateDto
     */
    'deliveryMethod'?: DeliveryMethod;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateDto
     */
    'deliveryCost'?: number;
    /**
     * 
     * @type {OrderPaymentStatus}
     * @memberof OrderCreateDto
     */
    'orderPaymentStatus'?: OrderPaymentStatus;
    /**
     * 
     * @type {OrderPaymentMethodDto}
     * @memberof OrderCreateDto
     */
    'paymentMethod'?: OrderPaymentMethodDto;
    /**
     * 
     * @type {InPostParcelMachineDetailsDto}
     * @memberof OrderCreateDto
     */
    'inPostParcelMachineDetails'?: InPostParcelMachineDetailsDto;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateDto
     */
    'shipmentId'?: number | null;
    /**
     * 
     * @type {OrderStage}
     * @memberof OrderCreateDto
     */
    'orderStage'?: OrderStage;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateDto
     */
    'discountCodeId'?: number | null;
    /**
     * 
     * @type {DiscountCodeDto}
     * @memberof OrderCreateDto
     */
    'discountCode'?: DiscountCodeDto;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateDto
     */
    'refundsSum'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderCreateResult = {
    Success: 'Success',
    Redirect: 'Redirect'
} as const;

export type OrderCreateResult = typeof OrderCreateResult[keyof typeof OrderCreateResult];


/**
 * 
 * @export
 * @interface OrderCreateResultDto
 */
export interface OrderCreateResultDto {
    /**
     * 
     * @type {string}
     * @memberof OrderCreateResultDto
     */
    'paymentRedirectUri'?: string | null;
    /**
     * 
     * @type {OrderCreateResult}
     * @memberof OrderCreateResultDto
     */
    'orderCreateResult'?: OrderCreateResult;
    /**
     * 
     * @type {OrderPaymentMethod}
     * @memberof OrderCreateResultDto
     */
    'orderPaymentMethod'?: OrderPaymentMethod;
}
/**
 * 
 * @export
 * @interface OrderDeliveryVariantCostDto
 */
export interface OrderDeliveryVariantCostDto {
    /**
     * 
     * @type {number}
     * @memberof OrderDeliveryVariantCostDto
     */
    'deliveryCost'?: number;
    /**
     * 
     * @type {DeliveryMethod}
     * @memberof OrderDeliveryVariantCostDto
     */
    'deliveryMethod'?: DeliveryMethod;
}
/**
 * 
 * @export
 * @interface OrderDetailsDto
 */
export interface OrderDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    'orderGuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    'orderNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsDto
     */
    'clientId'?: number | null;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof OrderDetailsDto
     */
    'client'?: ApplicationUserDto;
    /**
     * 
     * @type {UnregisteredUserDataDto}
     * @memberof OrderDetailsDto
     */
    'unregisteredUserData'?: UnregisteredUserDataDto;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    'optionalMessage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsDto
     */
    'deliveryAddressId'?: number | null;
    /**
     * 
     * @type {AddressDto}
     * @memberof OrderDetailsDto
     */
    'deliveryAddress'?: AddressDto;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDetailsDto
     */
    'deliveryAddressSameAsBillingAddress'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsDto
     */
    'billingAddressId'?: number | null;
    /**
     * 
     * @type {AddressDto}
     * @memberof OrderDetailsDto
     */
    'billingAddress'?: AddressDto;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsDto
     */
    'orderValue'?: number;
    /**
     * 
     * @type {DeliveryMethod}
     * @memberof OrderDetailsDto
     */
    'deliveryMethod'?: DeliveryMethod;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsDto
     */
    'deliveryCost'?: number;
    /**
     * 
     * @type {OrderPaymentStatus}
     * @memberof OrderDetailsDto
     */
    'orderPaymentStatus'?: OrderPaymentStatus;
    /**
     * 
     * @type {OrderPaymentMethodDto}
     * @memberof OrderDetailsDto
     */
    'paymentMethod'?: OrderPaymentMethodDto;
    /**
     * 
     * @type {InPostParcelMachineDetailsDto}
     * @memberof OrderDetailsDto
     */
    'inPostParcelMachineDetails'?: InPostParcelMachineDetailsDto;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsDto
     */
    'shipmentId'?: number | null;
    /**
     * 
     * @type {OrderStage}
     * @memberof OrderDetailsDto
     */
    'orderStage'?: OrderStage;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsDto
     */
    'discountCodeId'?: number | null;
    /**
     * 
     * @type {DiscountCodeDto}
     * @memberof OrderDetailsDto
     */
    'discountCode'?: DiscountCodeDto;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsDto
     */
    'refundsSum'?: number;
    /**
     * 
     * @type {Array<OrderProductDto>}
     * @memberof OrderDetailsDto
     */
    'orderProducts'?: Array<OrderProductDto> | null;
    /**
     * 
     * @type {Array<OrderRefundDto>}
     * @memberof OrderDetailsDto
     */
    'orderRefunds'?: Array<OrderRefundDto> | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsDto
     */
    'payUOrderId'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderDto
 */
export interface OrderDto {
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    'orderGuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    'orderNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    'clientId'?: number | null;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof OrderDto
     */
    'client'?: ApplicationUserDto;
    /**
     * 
     * @type {UnregisteredUserDataDto}
     * @memberof OrderDto
     */
    'unregisteredUserData'?: UnregisteredUserDataDto;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    'optionalMessage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    'deliveryAddressId'?: number | null;
    /**
     * 
     * @type {AddressDto}
     * @memberof OrderDto
     */
    'deliveryAddress'?: AddressDto;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDto
     */
    'deliveryAddressSameAsBillingAddress'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    'billingAddressId'?: number | null;
    /**
     * 
     * @type {AddressDto}
     * @memberof OrderDto
     */
    'billingAddress'?: AddressDto;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    'orderValue'?: number;
    /**
     * 
     * @type {DeliveryMethod}
     * @memberof OrderDto
     */
    'deliveryMethod'?: DeliveryMethod;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    'deliveryCost'?: number;
    /**
     * 
     * @type {OrderPaymentStatus}
     * @memberof OrderDto
     */
    'orderPaymentStatus'?: OrderPaymentStatus;
    /**
     * 
     * @type {OrderPaymentMethodDto}
     * @memberof OrderDto
     */
    'paymentMethod'?: OrderPaymentMethodDto;
    /**
     * 
     * @type {InPostParcelMachineDetailsDto}
     * @memberof OrderDto
     */
    'inPostParcelMachineDetails'?: InPostParcelMachineDetailsDto;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    'shipmentId'?: number | null;
    /**
     * 
     * @type {OrderStage}
     * @memberof OrderDto
     */
    'orderStage'?: OrderStage;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    'discountCodeId'?: number | null;
    /**
     * 
     * @type {DiscountCodeDto}
     * @memberof OrderDto
     */
    'discountCode'?: DiscountCodeDto;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    'refundsSum'?: number;
}
/**
 * 
 * @export
 * @interface OrderMinimalDto
 */
export interface OrderMinimalDto {
    /**
     * 
     * @type {number}
     * @memberof OrderMinimalDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderMinimalDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderMinimalDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderMinimalDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderMinimalDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {OrderPaymentStatus}
     * @memberof OrderMinimalDto
     */
    'orderPaymentStatus'?: OrderPaymentStatus;
    /**
     * 
     * @type {OrderStage}
     * @memberof OrderMinimalDto
     */
    'orderStage'?: OrderStage;
    /**
     * 
     * @type {OrderPaymentMethodDto}
     * @memberof OrderMinimalDto
     */
    'paymentMethod'?: OrderPaymentMethodDto;
    /**
     * 
     * @type {string}
     * @memberof OrderMinimalDto
     */
    'orderNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderMinimalDto
     */
    'orderValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderMinimalDto
     */
    'refundsSum'?: number;
}
/**
 * 
 * @export
 * @interface OrderPaymentMethod
 */
export interface OrderPaymentMethod {
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentMethod
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentMethod
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderPaymentMethodDto
 */
export interface OrderPaymentMethodDto {
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentMethodDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentMethodDto
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderPaymentStatus = {
    Pending: 'PENDING',
    WaitingForConfimration: 'WAITING_FOR_CONFIMRATION',
    Completed: 'COMPLETED',
    Canceled: 'CANCELED'
} as const;

export type OrderPaymentStatus = typeof OrderPaymentStatus[keyof typeof OrderPaymentStatus];


/**
 * 
 * @export
 * @interface OrderPlaceDto
 */
export interface OrderPlaceDto {
    /**
     * 
     * @type {string}
     * @memberof OrderPlaceDto
     */
    'basketId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPlaceDto
     */
    'turnstileToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPlaceDto
     */
    'optionalOrderMessage'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderProductCreateDto
 */
export interface OrderProductCreateDto {
    /**
     * 
     * @type {number}
     * @memberof OrderProductCreateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductCreateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderProductCreateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductCreateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderProductCreateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderProductCreateDto
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductCreateDto
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderProductCreateDto
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductCreateDto
     */
    'productName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductCreateDto
     */
    'productDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderProductCreateDto
     */
    'unitPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductCreateDto
     */
    'orderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductCreateDto
     */
    'productPreviewImageId'?: number;
}
/**
 * 
 * @export
 * @interface OrderProductDetailsDto
 */
export interface OrderProductDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof OrderProductDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDetailsDto
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductDetailsDto
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDetailsDto
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductDetailsDto
     */
    'productName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductDetailsDto
     */
    'productDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDetailsDto
     */
    'unitPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDetailsDto
     */
    'orderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDetailsDto
     */
    'productPreviewImageId'?: number;
}
/**
 * 
 * @export
 * @interface OrderProductDto
 */
export interface OrderProductDto {
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductDto
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductDto
     */
    'productName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductDto
     */
    'productDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto
     */
    'unitPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto
     */
    'orderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto
     */
    'productPreviewImageId'?: number;
}
/**
 * 
 * @export
 * @interface OrderProductStockUpdateDto
 */
export interface OrderProductStockUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof OrderProductStockUpdateDto
     */
    'orderId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderProductStockUpdateDto
     */
    'shouldUpdateStock'?: boolean;
}
/**
 * 
 * @export
 * @interface OrderProductUpdateDto
 */
export interface OrderProductUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof OrderProductUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderProductUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderProductUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderProductUpdateDto
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductUpdateDto
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderProductUpdateDto
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductUpdateDto
     */
    'productName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductUpdateDto
     */
    'productDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderProductUpdateDto
     */
    'unitPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductUpdateDto
     */
    'orderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductUpdateDto
     */
    'productPreviewImageId'?: number;
}
/**
 * 
 * @export
 * @interface OrderRefundCreateDto
 */
export interface OrderRefundCreateDto {
    /**
     * 
     * @type {number}
     * @memberof OrderRefundCreateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundCreateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderRefundCreateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundCreateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderRefundCreateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundCreateDto
     */
    'refundId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundCreateDto
     */
    'creationDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundCreateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundCreateDto
     */
    'amount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundCreateDto
     */
    'extRefundId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundCreateDto
     */
    'currencyCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundCreateDto
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundCreateDto
     */
    'statusCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundCreateDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundCreateDto
     */
    'statusDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundCreateDto
     */
    'orderGuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderRefundCreateDto
     */
    'orderId'?: number;
}
/**
 * 
 * @export
 * @interface OrderRefundDetailsDto
 */
export interface OrderRefundDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof OrderRefundDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderRefundDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderRefundDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDetailsDto
     */
    'refundId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDetailsDto
     */
    'creationDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDetailsDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDetailsDto
     */
    'amount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDetailsDto
     */
    'extRefundId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDetailsDto
     */
    'currencyCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDetailsDto
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDetailsDto
     */
    'statusCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDetailsDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDetailsDto
     */
    'statusDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDetailsDto
     */
    'orderGuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderRefundDetailsDto
     */
    'orderId'?: number;
    /**
     * 
     * @type {OrderDto}
     * @memberof OrderRefundDetailsDto
     */
    'order'?: OrderDto;
}
/**
 * 
 * @export
 * @interface OrderRefundDto
 */
export interface OrderRefundDto {
    /**
     * 
     * @type {number}
     * @memberof OrderRefundDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderRefundDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderRefundDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDto
     */
    'refundId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDto
     */
    'creationDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDto
     */
    'amount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDto
     */
    'extRefundId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDto
     */
    'currencyCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDto
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDto
     */
    'statusCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDto
     */
    'statusDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundDto
     */
    'orderGuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderRefundDto
     */
    'orderId'?: number;
}
/**
 * 
 * @export
 * @interface OrderRefundUpdateDto
 */
export interface OrderRefundUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof OrderRefundUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderRefundUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderRefundUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundUpdateDto
     */
    'refundId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundUpdateDto
     */
    'creationDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundUpdateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundUpdateDto
     */
    'amount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundUpdateDto
     */
    'extRefundId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundUpdateDto
     */
    'currencyCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundUpdateDto
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundUpdateDto
     */
    'statusCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundUpdateDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundUpdateDto
     */
    'statusDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRefundUpdateDto
     */
    'orderGuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderRefundUpdateDto
     */
    'orderId'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderSortDirections = {
    DateAscending: 'DateAscending',
    DateDescending: 'DateDescending',
    CostAscending: 'CostAscending',
    CostDescending: 'CostDescending',
    OrderState: 'OrderState'
} as const;

export type OrderSortDirections = typeof OrderSortDirections[keyof typeof OrderSortDirections];


/**
 * 
 * @export
 * @enum {string}
 */

export const OrderStage = {
    Abandoned: 'Abandoned',
    New: 'New',
    WaitingForPayment: 'WaitingForPayment',
    Paid: 'Paid',
    Confirmed: 'Confirmed',
    Sent: 'Sent',
    Delivered: 'Delivered',
    Canceled: 'Canceled'
} as const;

export type OrderStage = typeof OrderStage[keyof typeof OrderStage];


/**
 * 
 * @export
 * @interface OrderUpdateDto
 */
export interface OrderUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof OrderUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateDto
     */
    'orderGuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateDto
     */
    'orderNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderUpdateDto
     */
    'clientId'?: number | null;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof OrderUpdateDto
     */
    'client'?: ApplicationUserDto;
    /**
     * 
     * @type {UnregisteredUserDataDto}
     * @memberof OrderUpdateDto
     */
    'unregisteredUserData'?: UnregisteredUserDataDto;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateDto
     */
    'optionalMessage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderUpdateDto
     */
    'deliveryAddressId'?: number | null;
    /**
     * 
     * @type {AddressDto}
     * @memberof OrderUpdateDto
     */
    'deliveryAddress'?: AddressDto;
    /**
     * 
     * @type {boolean}
     * @memberof OrderUpdateDto
     */
    'deliveryAddressSameAsBillingAddress'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderUpdateDto
     */
    'billingAddressId'?: number | null;
    /**
     * 
     * @type {AddressDto}
     * @memberof OrderUpdateDto
     */
    'billingAddress'?: AddressDto;
    /**
     * 
     * @type {number}
     * @memberof OrderUpdateDto
     */
    'orderValue'?: number;
    /**
     * 
     * @type {DeliveryMethod}
     * @memberof OrderUpdateDto
     */
    'deliveryMethod'?: DeliveryMethod;
    /**
     * 
     * @type {number}
     * @memberof OrderUpdateDto
     */
    'deliveryCost'?: number;
    /**
     * 
     * @type {OrderPaymentStatus}
     * @memberof OrderUpdateDto
     */
    'orderPaymentStatus'?: OrderPaymentStatus;
    /**
     * 
     * @type {OrderPaymentMethodDto}
     * @memberof OrderUpdateDto
     */
    'paymentMethod'?: OrderPaymentMethodDto;
    /**
     * 
     * @type {InPostParcelMachineDetailsDto}
     * @memberof OrderUpdateDto
     */
    'inPostParcelMachineDetails'?: InPostParcelMachineDetailsDto;
    /**
     * 
     * @type {number}
     * @memberof OrderUpdateDto
     */
    'shipmentId'?: number | null;
    /**
     * 
     * @type {OrderStage}
     * @memberof OrderUpdateDto
     */
    'orderStage'?: OrderStage;
    /**
     * 
     * @type {number}
     * @memberof OrderUpdateDto
     */
    'discountCodeId'?: number | null;
    /**
     * 
     * @type {DiscountCodeDto}
     * @memberof OrderUpdateDto
     */
    'discountCode'?: DiscountCodeDto;
    /**
     * 
     * @type {number}
     * @memberof OrderUpdateDto
     */
    'refundsSum'?: number;
}
/**
 * 
 * @export
 * @interface PayByLink
 */
export interface PayByLink {
    /**
     * 
     * @type {string}
     * @memberof PayByLink
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayByLink
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayByLink
     */
    'brandImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayByLink
     */
    'status'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PayByLink
     */
    'minAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PayByLink
     */
    'maxAmount'?: number;
}
/**
 * 
 * @export
 * @interface PayUAvailablePayMethods
 */
export interface PayUAvailablePayMethods {
    /**
     * 
     * @type {Array<PayByLink>}
     * @memberof PayUAvailablePayMethods
     */
    'payByLinks'?: Array<PayByLink> | null;
}
/**
 * 
 * @export
 * @interface PayUOrderNotification
 */
export interface PayUOrderNotification {
    /**
     * 
     * @type {PayUOrderNotificationOrder}
     * @memberof PayUOrderNotification
     */
    'order'?: PayUOrderNotificationOrder;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotification
     */
    'localReceiptDateTime'?: string;
    /**
     * 
     * @type {Array<PayUOrderNotificationProperties>}
     * @memberof PayUOrderNotification
     */
    'properties'?: Array<PayUOrderNotificationProperties> | null;
}
/**
 * 
 * @export
 * @interface PayUOrderNotificationOrder
 */
export interface PayUOrderNotificationOrder {
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationOrder
     */
    'orderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationOrder
     */
    'extOrderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationOrder
     */
    'orderCreateDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationOrder
     */
    'notifyUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationOrder
     */
    'customerIp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationOrder
     */
    'merchantPosId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationOrder
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationOrder
     */
    'currencyCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PayUOrderNotificationOrder
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationOrder
     */
    'status'?: string | null;
    /**
     * 
     * @type {Array<PayUOrderProductRequest>}
     * @memberof PayUOrderNotificationOrder
     */
    'products'?: Array<PayUOrderProductRequest> | null;
}
/**
 * 
 * @export
 * @interface PayUOrderNotificationProperties
 */
export interface PayUOrderNotificationProperties {
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationProperties
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationProperties
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface PayUOrderProductRequest
 */
export interface PayUOrderProductRequest {
    /**
     * 
     * @type {string}
     * @memberof PayUOrderProductRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderProductRequest
     */
    'unitPrice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderProductRequest
     */
    'quantity'?: string | null;
}
/**
 * 
 * @export
 * @interface PriceCreateDto
 */
export interface PriceCreateDto {
    /**
     * 
     * @type {number}
     * @memberof PriceCreateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PriceCreateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof PriceCreateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof PriceCreateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PriceCreateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PriceCreateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PriceCreateDto
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceCreateDto
     */
    'menuEntryId'?: number;
    /**
     * 
     * @type {MenuEntryDto}
     * @memberof PriceCreateDto
     */
    'menuEntry'?: MenuEntryDto;
}
/**
 * 
 * @export
 * @interface PriceDetailsDto
 */
export interface PriceDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof PriceDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PriceDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof PriceDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof PriceDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PriceDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PriceDetailsDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PriceDetailsDto
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceDetailsDto
     */
    'menuEntryId'?: number;
    /**
     * 
     * @type {MenuEntryDto}
     * @memberof PriceDetailsDto
     */
    'menuEntry'?: MenuEntryDto;
}
/**
 * 
 * @export
 * @interface PriceDto
 */
export interface PriceDto {
    /**
     * 
     * @type {number}
     * @memberof PriceDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PriceDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof PriceDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof PriceDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PriceDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PriceDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PriceDto
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceDto
     */
    'menuEntryId'?: number;
    /**
     * 
     * @type {MenuEntryDto}
     * @memberof PriceDto
     */
    'menuEntry'?: MenuEntryDto;
}
/**
 * 
 * @export
 * @interface PriceUpdateDto
 */
export interface PriceUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof PriceUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PriceUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof PriceUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof PriceUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PriceUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PriceUpdateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PriceUpdateDto
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceUpdateDto
     */
    'menuEntryId'?: number;
    /**
     * 
     * @type {MenuEntryDto}
     * @memberof PriceUpdateDto
     */
    'menuEntry'?: MenuEntryDto;
}
/**
 * 
 * @export
 * @interface ProductCreateDto
 */
export interface ProductCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductCreateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductCreateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductCreateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    'wineVariety'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductCreateDto
     */
    'categoriesIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof ProductCreateDto
     */
    'categories'?: Array<CategoryDto> | null;
    /**
     * 
     * @type {Array<ProductPriceDto>}
     * @memberof ProductCreateDto
     */
    'prices'?: Array<ProductPriceDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductCreateDto
     */
    'imagesIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<ProductImageDto>}
     * @memberof ProductCreateDto
     */
    'productImageDtos'?: Array<ProductImageDto> | null;
    /**
     * 
     * @type {Array<ProductDetailDto>}
     * @memberof ProductCreateDto
     */
    'details'?: Array<ProductDetailDto> | null;
    /**
     * 
     * @type {Array<ProductStatisticsDto>}
     * @memberof ProductCreateDto
     */
    'statistics'?: Array<ProductStatisticsDto> | null;
    /**
     * 
     * @type {Array<TagDto>}
     * @memberof ProductCreateDto
     */
    'tags'?: Array<TagDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductCreateDto
     */
    'tagsIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface ProductDetailCreateDto
 */
export interface ProductDetailCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductDetailCreateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailCreateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailCreateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailCreateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailCreateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailCreateDto
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailCreateDto
     */
    'header'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailCreateDto
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductDetailDetailsDto
 */
export interface ProductDetailDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ProductDetailDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailDetailsDto
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDetailsDto
     */
    'header'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDetailsDto
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductDetailDto
 */
export interface ProductDetailDto {
    /**
     * 
     * @type {number}
     * @memberof ProductDetailDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailDto
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDto
     */
    'header'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDto
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductDetailUpdateDto
 */
export interface ProductDetailUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductDetailUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailUpdateDto
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailUpdateDto
     */
    'header'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailUpdateDto
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductDetailsDto
 */
export interface ProductDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsDto
     */
    'wineVariety'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductDetailsDto
     */
    'categoriesIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof ProductDetailsDto
     */
    'categories'?: Array<CategoryDto> | null;
    /**
     * 
     * @type {Array<ProductPriceDto>}
     * @memberof ProductDetailsDto
     */
    'prices'?: Array<ProductPriceDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductDetailsDto
     */
    'imagesIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<ProductImageDto>}
     * @memberof ProductDetailsDto
     */
    'productImageDtos'?: Array<ProductImageDto> | null;
    /**
     * 
     * @type {Array<ProductDetailDto>}
     * @memberof ProductDetailsDto
     */
    'details'?: Array<ProductDetailDto> | null;
    /**
     * 
     * @type {Array<ProductStatisticsDto>}
     * @memberof ProductDetailsDto
     */
    'statistics'?: Array<ProductStatisticsDto> | null;
    /**
     * 
     * @type {Array<TagDto>}
     * @memberof ProductDetailsDto
     */
    'tags'?: Array<TagDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductDetailsDto
     */
    'tagsIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<ProductImageDto>}
     * @memberof ProductDetailsDto
     */
    'images'?: Array<ProductImageDto> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductDetailsDto
     */
    'imagesUrls'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'wineVariety'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductDto
     */
    'categoriesIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof ProductDto
     */
    'categories'?: Array<CategoryDto> | null;
    /**
     * 
     * @type {Array<ProductPriceDto>}
     * @memberof ProductDto
     */
    'prices'?: Array<ProductPriceDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductDto
     */
    'imagesIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<ProductImageDto>}
     * @memberof ProductDto
     */
    'productImageDtos'?: Array<ProductImageDto> | null;
    /**
     * 
     * @type {Array<ProductDetailDto>}
     * @memberof ProductDto
     */
    'details'?: Array<ProductDetailDto> | null;
    /**
     * 
     * @type {Array<ProductStatisticsDto>}
     * @memberof ProductDto
     */
    'statistics'?: Array<ProductStatisticsDto> | null;
    /**
     * 
     * @type {Array<TagDto>}
     * @memberof ProductDto
     */
    'tags'?: Array<TagDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductDto
     */
    'tagsIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface ProductImageDetailsDto
 */
export interface ProductImageDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ProductImageDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductImageDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductImageDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductImageDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductImageDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductImageDetailsDto
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductImageDetailsDto
     */
    'contentType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductImageDetailsDto
     */
    'position'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductImageDetailsDto
     */
    'productId'?: number;
}
/**
 * 
 * @export
 * @interface ProductImageDto
 */
export interface ProductImageDto {
    /**
     * 
     * @type {number}
     * @memberof ProductImageDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductImageDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductImageDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductImageDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductImageDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductImageDto
     */
    'position'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductImageDto
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductImageDto
     */
    'fileName'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductImageUpdateDto
 */
export interface ProductImageUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductImageUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductImageUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductImageUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductImageUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductImageUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductImageUpdateDto
     */
    'position'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductImageUpdateDto
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductImageUpdateDto
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductImageUpdateDto
     */
    'contentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductImageUpdateDto
     */
    'blobName'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductPriceCreateDto
 */
export interface ProductPriceCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductPriceCreateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceCreateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceCreateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceCreateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceCreateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceCreateDto
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceCreateDto
     */
    'unitValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceCreateDto
     */
    'taxRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceCreateDto
     */
    'unitStock'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceCreateDto
     */
    'productId'?: number;
}
/**
 * 
 * @export
 * @interface ProductPriceDetailsDto
 */
export interface ProductPriceDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ProductPriceDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceDetailsDto
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceDetailsDto
     */
    'unitValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceDetailsDto
     */
    'taxRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceDetailsDto
     */
    'unitStock'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceDetailsDto
     */
    'productId'?: number;
}
/**
 * 
 * @export
 * @interface ProductPriceDto
 */
export interface ProductPriceDto {
    /**
     * 
     * @type {number}
     * @memberof ProductPriceDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceDto
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceDto
     */
    'unitValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceDto
     */
    'taxRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceDto
     */
    'unitStock'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceDto
     */
    'productId'?: number;
}
/**
 * 
 * @export
 * @interface ProductPriceForShopDto
 */
export interface ProductPriceForShopDto {
    /**
     * 
     * @type {number}
     * @memberof ProductPriceForShopDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceForShopDto
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceForShopDto
     */
    'unitValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceForShopDto
     */
    'unitStock'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceForShopDto
     */
    'taxRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceForShopDto
     */
    'productId'?: number;
}
/**
 * 
 * @export
 * @interface ProductPriceUpdateDto
 */
export interface ProductPriceUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductPriceUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceUpdateDto
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceUpdateDto
     */
    'unitValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceUpdateDto
     */
    'taxRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceUpdateDto
     */
    'unitStock'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceUpdateDto
     */
    'productId'?: number;
}
/**
 * 
 * @export
 * @interface ProductStatisticsCreateDto
 */
export interface ProductStatisticsCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductStatisticsCreateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductStatisticsCreateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductStatisticsCreateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductStatisticsCreateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductStatisticsCreateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductStatisticsCreateDto
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductStatisticsCreateDto
     */
    'header'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductStatisticsCreateDto
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductStatisticsDetailsDto
 */
export interface ProductStatisticsDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ProductStatisticsDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductStatisticsDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductStatisticsDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductStatisticsDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductStatisticsDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductStatisticsDetailsDto
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductStatisticsDetailsDto
     */
    'header'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductStatisticsDetailsDto
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductStatisticsDto
 */
export interface ProductStatisticsDto {
    /**
     * 
     * @type {number}
     * @memberof ProductStatisticsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductStatisticsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductStatisticsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductStatisticsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductStatisticsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductStatisticsDto
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductStatisticsDto
     */
    'header'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductStatisticsDto
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductStatisticsUpdateDto
 */
export interface ProductStatisticsUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductStatisticsUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductStatisticsUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductStatisticsUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductStatisticsUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductStatisticsUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductStatisticsUpdateDto
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductStatisticsUpdateDto
     */
    'header'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductStatisticsUpdateDto
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductTagsUpdateDto
 */
export interface ProductTagsUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductTagsUpdateDto
     */
    'productId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductTagsUpdateDto
     */
    'tagsIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface ProductUpdateDto
 */
export interface ProductUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    'wineVariety'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductUpdateDto
     */
    'categoriesIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductUpdateDto
     */
    'imagesIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductUpdateDto
     */
    'tagsIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface RemoveFromBasketDto
 */
export interface RemoveFromBasketDto {
    /**
     * 
     * @type {string}
     * @memberof RemoveFromBasketDto
     */
    'basketItemId'?: string;
    /**
     * 
     * @type {number}
     * @memberof RemoveFromBasketDto
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface ShinefarContactDto
 */
export interface ShinefarContactDto {
    /**
     * 
     * @type {string}
     * @memberof ShinefarContactDto
     */
    'firstAndLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShinefarContactDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShinefarContactDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShinefarContactDto
     */
    'tinNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShinefarContactDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {ShinefarPersonality}
     * @memberof ShinefarContactDto
     */
    'shinefarPersonality'?: ShinefarPersonality;
    /**
     * 
     * @type {Language}
     * @memberof ShinefarContactDto
     */
    'language'?: Language;
    /**
     * 
     * @type {string}
     * @memberof ShinefarContactDto
     */
    'turnstileToken'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ShinefarPersonality = {
    Installer: 'Installer',
    Investor: 'Investor',
    Other: 'Other'
} as const;

export type ShinefarPersonality = typeof ShinefarPersonality[keyof typeof ShinefarPersonality];


/**
 * 
 * @export
 * @interface StartForgotPasswordDto
 */
export interface StartForgotPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof StartForgotPasswordDto
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface TagCreateDto
 */
export interface TagCreateDto {
    /**
     * 
     * @type {number}
     * @memberof TagCreateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TagCreateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof TagCreateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof TagCreateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TagCreateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof TagCreateDto
     */
    'productsIds'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof TagCreateDto
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface TagDetailsDto
 */
export interface TagDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof TagDetailsDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TagDetailsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof TagDetailsDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof TagDetailsDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TagDetailsDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof TagDetailsDto
     */
    'productsIds'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof TagDetailsDto
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface TagDto
 */
export interface TagDto {
    /**
     * 
     * @type {number}
     * @memberof TagDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TagDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof TagDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof TagDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TagDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof TagDto
     */
    'productsIds'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof TagDto
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface TagUpdateDto
 */
export interface TagUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof TagUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TagUpdateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof TagUpdateDto
     */
    'createdById'?: number;
    /**
     * 
     * @type {string}
     * @memberof TagUpdateDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TagUpdateDto
     */
    'updatedById'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof TagUpdateDto
     */
    'productsIds'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof TagUpdateDto
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface UnregisteredUserDataDto
 */
export interface UnregisteredUserDataDto {
    /**
     * 
     * @type {string}
     * @memberof UnregisteredUserDataDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UnregisteredUserDataDto
     */
    'lastName'?: string | null;
}

/**
 * AddressApi - axios parameter creator
 * @export
 */
export const AddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAddressGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAddressIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAddressIdDelete', 'id', id)
            const localVarPath = `/api/Address/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAddressIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAddressIdGet', 'id', id)
            const localVarPath = `/api/Address/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AddressUpdateDto} [addressUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAddressIdPatch: async (id: number, addressUpdateDto?: AddressUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAddressIdPatch', 'id', id)
            const localVarPath = `/api/Address/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addressUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AddressUpdateDto} [addressUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAddressIdPut: async (id: number, addressUpdateDto?: AddressUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAddressIdPut', 'id', id)
            const localVarPath = `/api/Address/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addressUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddressCreateDto} [addressCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAddressPost: async (addressCreateDto?: AddressCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addressCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAddressUserAddressesUserIdGet: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiAddressUserAddressesUserIdGet', 'userId', userId)
            const localVarPath = `/api/Address/userAddresses/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddressApi - functional programming interface
 * @export
 */
export const AddressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddressApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAddressGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddressDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAddressGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAddressIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAddressIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAddressIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAddressIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AddressUpdateDto} [addressUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAddressIdPatch(id: number, addressUpdateDto?: AddressUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAddressIdPatch(id, addressUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AddressUpdateDto} [addressUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAddressIdPut(id: number, addressUpdateDto?: AddressUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAddressIdPut(id, addressUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddressCreateDto} [addressCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAddressPost(addressCreateDto?: AddressCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAddressPost(addressCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAddressUserAddressesUserIdGet(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddressDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAddressUserAddressesUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AddressApi - factory interface
 * @export
 */
export const AddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddressApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAddressGet(options?: any): AxiosPromise<Array<AddressDto>> {
            return localVarFp.apiAddressGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAddressIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAddressIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAddressIdGet(id: number, options?: any): AxiosPromise<AddressDetailsDto> {
            return localVarFp.apiAddressIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AddressUpdateDto} [addressUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAddressIdPatch(id: number, addressUpdateDto?: AddressUpdateDto, options?: any): AxiosPromise<AddressDetailsDto> {
            return localVarFp.apiAddressIdPatch(id, addressUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AddressUpdateDto} [addressUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAddressIdPut(id: number, addressUpdateDto?: AddressUpdateDto, options?: any): AxiosPromise<AddressDetailsDto> {
            return localVarFp.apiAddressIdPut(id, addressUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddressCreateDto} [addressCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAddressPost(addressCreateDto?: AddressCreateDto, options?: any): AxiosPromise<AddressDetailsDto> {
            return localVarFp.apiAddressPost(addressCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAddressUserAddressesUserIdGet(userId: number, options?: any): AxiosPromise<Array<AddressDto>> {
            return localVarFp.apiAddressUserAddressesUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddressApi - object-oriented interface
 * @export
 * @class AddressApi
 * @extends {BaseAPI}
 */
export class AddressApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiAddressGet(options?: AxiosRequestConfig) {
        return AddressApiFp(this.configuration).apiAddressGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiAddressIdDelete(id: number, options?: AxiosRequestConfig) {
        return AddressApiFp(this.configuration).apiAddressIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiAddressIdGet(id: number, options?: AxiosRequestConfig) {
        return AddressApiFp(this.configuration).apiAddressIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AddressUpdateDto} [addressUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiAddressIdPatch(id: number, addressUpdateDto?: AddressUpdateDto, options?: AxiosRequestConfig) {
        return AddressApiFp(this.configuration).apiAddressIdPatch(id, addressUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AddressUpdateDto} [addressUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiAddressIdPut(id: number, addressUpdateDto?: AddressUpdateDto, options?: AxiosRequestConfig) {
        return AddressApiFp(this.configuration).apiAddressIdPut(id, addressUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddressCreateDto} [addressCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiAddressPost(addressCreateDto?: AddressCreateDto, options?: AxiosRequestConfig) {
        return AddressApiFp(this.configuration).apiAddressPost(addressCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiAddressUserAddressesUserIdGet(userId: number, options?: AxiosRequestConfig) {
        return AddressApiFp(this.configuration).apiAddressUserAddressesUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationUserApi - axios parameter creator
 * @export
 */
export const ApplicationUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserActivatePost: async (applicationUserActivationDto?: ApplicationUserActivationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserActivationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserBeginForgotPasswordPost: async (startForgotPasswordDto?: StartForgotPasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/beginForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startForgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationUserChangePasswordDto} [applicationUserChangePasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserChangePasswordPost: async (applicationUserChangePasswordDto?: ApplicationUserChangePasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/changePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserChangePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserCurrentUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/currentUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserEndForgotPasswordPost: async (endForgotPasswordDto?: EndForgotPasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/endForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(endForgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdDelete', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdGet', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPatch: async (id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdPatch', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPut: async (id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdPut', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdRolesPost: async (id: number, applicationUserRole?: Array<ApplicationUserRole>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdRolesPost', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}/roles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [newNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPhoneUpdatePatch: async (newNumber?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/phoneUpdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (newNumber !== undefined) {
                localVarQueryParameter['newNumber'] = newNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPost: async (applicationUserRegisterDto?: ApplicationUserRegisterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserRegisterPost: async (applicationUserRegisterDto?: ApplicationUserRegisterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserResendGet: async (email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationUserApi - functional programming interface
 * @export
 */
export const ApplicationUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserActivatePost(applicationUserActivationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationUserChangePasswordDto} [applicationUserChangePasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserChangePasswordPost(applicationUserChangePasswordDto?: ApplicationUserChangePasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserChangePasswordPost(applicationUserChangePasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserCurrentUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserCurrentUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdPatch(id, applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdPut(id, applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdRolesPost(id, applicationUserRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [newNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserPhoneUpdatePatch(newNumber?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserPhoneUpdatePatch(newNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserPost(applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserRegisterPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserRegisterPost(applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserResendGet(email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserResendGet(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationUserApi - factory interface
 * @export
 */
export const ApplicationUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationUserApiFp(configuration)
    return {
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserActivatePost(applicationUserActivationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationUserChangePasswordDto} [applicationUserChangePasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserChangePasswordPost(applicationUserChangePasswordDto?: ApplicationUserChangePasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserChangePasswordPost(applicationUserChangePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserCurrentUserGet(options?: any): AxiosPromise<ApplicationUserDto> {
            return localVarFp.apiApplicationUserCurrentUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserGet(options?: any): AxiosPromise<Array<ApplicationUserDto>> {
            return localVarFp.apiApplicationUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdGet(id: number, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdPatch(id, applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdPut(id, applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserIdRolesPost(id, applicationUserRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [newNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPhoneUpdatePatch(newNumber?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserPhoneUpdatePatch(newNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserPost(applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserRegisterPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserRegisterPost(applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserResendGet(email?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserResendGet(email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationUserApi - object-oriented interface
 * @export
 * @class ApplicationUserApi
 * @extends {BaseAPI}
 */
export class ApplicationUserApi extends BaseAPI {
    /**
     * 
     * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserActivatePost(applicationUserActivationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationUserChangePasswordDto} [applicationUserChangePasswordDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserChangePasswordPost(applicationUserChangePasswordDto?: ApplicationUserChangePasswordDto, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserChangePasswordPost(applicationUserChangePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserCurrentUserGet(options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserCurrentUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserGet(options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdDelete(id: number, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdGet(id: number, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdPatch(id, applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdPut(id, applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<ApplicationUserRole>} [applicationUserRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdRolesPost(id, applicationUserRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [newNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserPhoneUpdatePatch(newNumber?: string, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserPhoneUpdatePatch(newNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserPost(applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserRegisterPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserRegisterPost(applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserResendGet(email?: string, options?: AxiosRequestConfig) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserResendGet(email, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginCallbackGet: async (returnUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/loginCallback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [loginPageUrl] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginGet: async (loginPageUrl?: string, returnUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (loginPageUrl !== undefined) {
                localVarQueryParameter['loginPageUrl'] = loginPageUrl;
            }

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginPost: async (returnUrl?: string, loginDto?: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [postLogoutUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLogoutGet: async (postLogoutUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (postLogoutUrl !== undefined) {
                localVarQueryParameter['postLogoutUrl'] = postLogoutUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLoginCallbackGet(returnUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLoginCallbackGet(returnUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [loginPageUrl] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLoginGet(loginPageUrl?: string, returnUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLoginGet(loginPageUrl, returnUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLoginPost(returnUrl?: string, loginDto?: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLoginPost(returnUrl, loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [postLogoutUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLogoutGet(postLogoutUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLogoutGet(postLogoutUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginCallbackGet(returnUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLoginCallbackGet(returnUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [loginPageUrl] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginGet(loginPageUrl?: string, returnUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLoginGet(loginPageUrl, returnUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginPost(returnUrl?: string, loginDto?: LoginDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLoginPost(returnUrl, loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [postLogoutUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLogoutGet(postLogoutUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLogoutGet(postLogoutUrl, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLoginCallbackGet(returnUrl?: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLoginCallbackGet(returnUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [loginPageUrl] 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLoginGet(loginPageUrl?: string, returnUrl?: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLoginGet(loginPageUrl, returnUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [returnUrl] 
     * @param {LoginDto} [loginDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLoginPost(returnUrl?: string, loginDto?: LoginDto, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLoginPost(returnUrl, loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [postLogoutUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLogoutGet(postLogoutUrl?: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLogoutGet(postLogoutUrl, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BasketApi - axios parameter creator
 * @export
 */
export const BasketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [discountCode] 
         * @param {number} [orderAmount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketApplyDiscountCodePost: async (discountCode?: string, orderAmount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket/applyDiscountCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (discountCode !== undefined) {
                localVarQueryParameter['discountCode'] = discountCode;
            }

            if (orderAmount !== undefined) {
                localVarQueryParameter['orderAmount'] = orderAmount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketDeleteBasketIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBasketDeleteBasketIdDelete', 'id', id)
            const localVarPath = `/api/basket/deleteBasket/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketGetAllBasketsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket/getAllBaskets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketGetBasketByIdIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBasketGetBasketByIdIdGet', 'id', id)
            const localVarPath = `/api/basket/getBasketById/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddToBasketDto} [addToBasketDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketPost: async (addToBasketDto?: AddToBasketDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addToBasketDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketRemoveDiscountCodeDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket/removeDiscountCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RemoveFromBasketDto} [removeFromBasketDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketRemoveFromBasketDelete: async (removeFromBasketDto?: RemoveFromBasketDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket/removeFromBasket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeFromBasketDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BasketApi - functional programming interface
 * @export
 */
export const BasketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BasketApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [discountCode] 
         * @param {number} [orderAmount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketApplyDiscountCodePost(discountCode?: string, orderAmount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketApplyDiscountCodePost(discountCode, orderAmount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketDeleteBasketIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketDeleteBasketIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketGetAllBasketsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BasketDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketGetAllBasketsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketGetBasketByIdIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketGetBasketByIdIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddToBasketDto} [addToBasketDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketPost(addToBasketDto?: AddToBasketDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketPost(addToBasketDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketRemoveDiscountCodeDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketRemoveDiscountCodeDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RemoveFromBasketDto} [removeFromBasketDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketRemoveFromBasketDelete(removeFromBasketDto?: RemoveFromBasketDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketRemoveFromBasketDelete(removeFromBasketDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BasketApi - factory interface
 * @export
 */
export const BasketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BasketApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [discountCode] 
         * @param {number} [orderAmount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketApplyDiscountCodePost(discountCode?: string, orderAmount?: number, options?: any): AxiosPromise<BasketDto> {
            return localVarFp.apiBasketApplyDiscountCodePost(discountCode, orderAmount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketDeleteBasketIdDelete(id: string, options?: any): AxiosPromise<BasketDto> {
            return localVarFp.apiBasketDeleteBasketIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketGet(options?: any): AxiosPromise<BasketDto> {
            return localVarFp.apiBasketGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketGetAllBasketsGet(options?: any): AxiosPromise<Array<BasketDto>> {
            return localVarFp.apiBasketGetAllBasketsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketGetBasketByIdIdGet(id: string, options?: any): AxiosPromise<BasketDto> {
            return localVarFp.apiBasketGetBasketByIdIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddToBasketDto} [addToBasketDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketPost(addToBasketDto?: AddToBasketDto, options?: any): AxiosPromise<BasketDto> {
            return localVarFp.apiBasketPost(addToBasketDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketRemoveDiscountCodeDelete(options?: any): AxiosPromise<BasketDto> {
            return localVarFp.apiBasketRemoveDiscountCodeDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RemoveFromBasketDto} [removeFromBasketDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketRemoveFromBasketDelete(removeFromBasketDto?: RemoveFromBasketDto, options?: any): AxiosPromise<BasketDto> {
            return localVarFp.apiBasketRemoveFromBasketDelete(removeFromBasketDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BasketApi - object-oriented interface
 * @export
 * @class BasketApi
 * @extends {BaseAPI}
 */
export class BasketApi extends BaseAPI {
    /**
     * 
     * @param {string} [discountCode] 
     * @param {number} [orderAmount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasketApi
     */
    public apiBasketApplyDiscountCodePost(discountCode?: string, orderAmount?: number, options?: AxiosRequestConfig) {
        return BasketApiFp(this.configuration).apiBasketApplyDiscountCodePost(discountCode, orderAmount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasketApi
     */
    public apiBasketDeleteBasketIdDelete(id: string, options?: AxiosRequestConfig) {
        return BasketApiFp(this.configuration).apiBasketDeleteBasketIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasketApi
     */
    public apiBasketGet(options?: AxiosRequestConfig) {
        return BasketApiFp(this.configuration).apiBasketGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasketApi
     */
    public apiBasketGetAllBasketsGet(options?: AxiosRequestConfig) {
        return BasketApiFp(this.configuration).apiBasketGetAllBasketsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasketApi
     */
    public apiBasketGetBasketByIdIdGet(id: string, options?: AxiosRequestConfig) {
        return BasketApiFp(this.configuration).apiBasketGetBasketByIdIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddToBasketDto} [addToBasketDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasketApi
     */
    public apiBasketPost(addToBasketDto?: AddToBasketDto, options?: AxiosRequestConfig) {
        return BasketApiFp(this.configuration).apiBasketPost(addToBasketDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasketApi
     */
    public apiBasketRemoveDiscountCodeDelete(options?: AxiosRequestConfig) {
        return BasketApiFp(this.configuration).apiBasketRemoveDiscountCodeDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RemoveFromBasketDto} [removeFromBasketDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasketApi
     */
    public apiBasketRemoveFromBasketDelete(removeFromBasketDto?: RemoveFromBasketDto, options?: AxiosRequestConfig) {
        return BasketApiFp(this.configuration).apiBasketRemoveFromBasketDelete(removeFromBasketDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BeerApi - axios parameter creator
 * @export
 */
export const BeerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBeerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Beer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBeerIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBeerIdDelete', 'id', id)
            const localVarPath = `/api/Beer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBeerIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBeerIdGet', 'id', id)
            const localVarPath = `/api/Beer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {BeerUpdateDto} [beerUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBeerIdPatch: async (id: number, beerUpdateDto?: BeerUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBeerIdPatch', 'id', id)
            const localVarPath = `/api/Beer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(beerUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {BeerUpdateDto} [beerUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBeerIdPut: async (id: number, beerUpdateDto?: BeerUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBeerIdPut', 'id', id)
            const localVarPath = `/api/Beer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(beerUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBeerPositionIdDirectionPatch: async (id: number, direction: Direction, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBeerPositionIdDirectionPatch', 'id', id)
            // verify required parameter 'direction' is not null or undefined
            assertParamExists('apiBeerPositionIdDirectionPatch', 'direction', direction)
            const localVarPath = `/api/Beer/Position/{id}/{direction}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"direction"}}`, encodeURIComponent(String(direction)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BeerCreateDto} [beerCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBeerPost: async (beerCreateDto?: BeerCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Beer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(beerCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BeerApi - functional programming interface
 * @export
 */
export const BeerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BeerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBeerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BeerDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBeerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBeerIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBeerIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBeerIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeerDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBeerIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {BeerUpdateDto} [beerUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBeerIdPatch(id: number, beerUpdateDto?: BeerUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeerDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBeerIdPatch(id, beerUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {BeerUpdateDto} [beerUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBeerIdPut(id: number, beerUpdateDto?: BeerUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeerDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBeerIdPut(id, beerUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBeerPositionIdDirectionPatch(id: number, direction: Direction, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BeerDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBeerPositionIdDirectionPatch(id, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BeerCreateDto} [beerCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBeerPost(beerCreateDto?: BeerCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeerDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBeerPost(beerCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BeerApi - factory interface
 * @export
 */
export const BeerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BeerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBeerGet(options?: any): AxiosPromise<Array<BeerDto>> {
            return localVarFp.apiBeerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBeerIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiBeerIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBeerIdGet(id: number, options?: any): AxiosPromise<BeerDetailsDto> {
            return localVarFp.apiBeerIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {BeerUpdateDto} [beerUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBeerIdPatch(id: number, beerUpdateDto?: BeerUpdateDto, options?: any): AxiosPromise<BeerDetailsDto> {
            return localVarFp.apiBeerIdPatch(id, beerUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {BeerUpdateDto} [beerUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBeerIdPut(id: number, beerUpdateDto?: BeerUpdateDto, options?: any): AxiosPromise<BeerDetailsDto> {
            return localVarFp.apiBeerIdPut(id, beerUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBeerPositionIdDirectionPatch(id: number, direction: Direction, options?: any): AxiosPromise<Array<BeerDto>> {
            return localVarFp.apiBeerPositionIdDirectionPatch(id, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BeerCreateDto} [beerCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBeerPost(beerCreateDto?: BeerCreateDto, options?: any): AxiosPromise<BeerDetailsDto> {
            return localVarFp.apiBeerPost(beerCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BeerApi - object-oriented interface
 * @export
 * @class BeerApi
 * @extends {BaseAPI}
 */
export class BeerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeerApi
     */
    public apiBeerGet(options?: AxiosRequestConfig) {
        return BeerApiFp(this.configuration).apiBeerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeerApi
     */
    public apiBeerIdDelete(id: number, options?: AxiosRequestConfig) {
        return BeerApiFp(this.configuration).apiBeerIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeerApi
     */
    public apiBeerIdGet(id: number, options?: AxiosRequestConfig) {
        return BeerApiFp(this.configuration).apiBeerIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {BeerUpdateDto} [beerUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeerApi
     */
    public apiBeerIdPatch(id: number, beerUpdateDto?: BeerUpdateDto, options?: AxiosRequestConfig) {
        return BeerApiFp(this.configuration).apiBeerIdPatch(id, beerUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {BeerUpdateDto} [beerUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeerApi
     */
    public apiBeerIdPut(id: number, beerUpdateDto?: BeerUpdateDto, options?: AxiosRequestConfig) {
        return BeerApiFp(this.configuration).apiBeerIdPut(id, beerUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Direction} direction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeerApi
     */
    public apiBeerPositionIdDirectionPatch(id: number, direction: Direction, options?: AxiosRequestConfig) {
        return BeerApiFp(this.configuration).apiBeerPositionIdDirectionPatch(id, direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BeerCreateDto} [beerCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeerApi
     */
    public apiBeerPost(beerCreateDto?: BeerCreateDto, options?: AxiosRequestConfig) {
        return BeerApiFp(this.configuration).apiBeerPost(beerCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CategoryApi - axios parameter creator
 * @export
 */
export const CategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCategoryIdDelete', 'id', id)
            const localVarPath = `/api/Category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCategoryIdGet', 'id', id)
            const localVarPath = `/api/Category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoryUpdateDto} [categoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryIdPatch: async (id: number, categoryUpdateDto?: CategoryUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCategoryIdPatch', 'id', id)
            const localVarPath = `/api/Category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoryUpdateDto} [categoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryIdPut: async (id: number, categoryUpdateDto?: CategoryUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCategoryIdPut', 'id', id)
            const localVarPath = `/api/Category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryParentCategoriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Category/parentCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CategoryCreateDto} [categoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryPost: async (categoryCreateDto?: CategoryCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategorySubcategoriesCategoryIdGet: async (categoryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('apiCategorySubcategoriesCategoryIdGet', 'categoryId', categoryId)
            const localVarPath = `/api/Category/subcategories/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoryIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoryIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoryIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoryUpdateDto} [categoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoryIdPatch(id: number, categoryUpdateDto?: CategoryUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoryIdPatch(id, categoryUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoryUpdateDto} [categoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoryIdPut(id: number, categoryUpdateDto?: CategoryUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoryIdPut(id, categoryUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoryParentCategoriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoryParentCategoriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CategoryCreateDto} [categoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoryPost(categoryCreateDto?: CategoryCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoryPost(categoryCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategorySubcategoriesCategoryIdGet(categoryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategorySubcategoriesCategoryIdGet(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryGet(options?: any): AxiosPromise<Array<CategoryDto>> {
            return localVarFp.apiCategoryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCategoryIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryIdGet(id: number, options?: any): AxiosPromise<CategoryDetailsDto> {
            return localVarFp.apiCategoryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoryUpdateDto} [categoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryIdPatch(id: number, categoryUpdateDto?: CategoryUpdateDto, options?: any): AxiosPromise<CategoryDetailsDto> {
            return localVarFp.apiCategoryIdPatch(id, categoryUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoryUpdateDto} [categoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryIdPut(id: number, categoryUpdateDto?: CategoryUpdateDto, options?: any): AxiosPromise<CategoryDetailsDto> {
            return localVarFp.apiCategoryIdPut(id, categoryUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryParentCategoriesGet(options?: any): AxiosPromise<Array<CategoryDto>> {
            return localVarFp.apiCategoryParentCategoriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CategoryCreateDto} [categoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryPost(categoryCreateDto?: CategoryCreateDto, options?: any): AxiosPromise<CategoryDetailsDto> {
            return localVarFp.apiCategoryPost(categoryCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategorySubcategoriesCategoryIdGet(categoryId: number, options?: any): AxiosPromise<Array<CategoryDto>> {
            return localVarFp.apiCategorySubcategoriesCategoryIdGet(categoryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiCategoryGet(options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).apiCategoryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiCategoryIdDelete(id: number, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).apiCategoryIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiCategoryIdGet(id: number, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).apiCategoryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CategoryUpdateDto} [categoryUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiCategoryIdPatch(id: number, categoryUpdateDto?: CategoryUpdateDto, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).apiCategoryIdPatch(id, categoryUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CategoryUpdateDto} [categoryUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiCategoryIdPut(id: number, categoryUpdateDto?: CategoryUpdateDto, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).apiCategoryIdPut(id, categoryUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiCategoryParentCategoriesGet(options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).apiCategoryParentCategoriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoryCreateDto} [categoryCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiCategoryPost(categoryCreateDto?: CategoryCreateDto, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).apiCategoryPost(categoryCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiCategorySubcategoriesCategoryIdGet(categoryId: number, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).apiCategorySubcategoriesCategoryIdGet(categoryId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeployPageApi - axios parameter creator
 * @export
 */
export const DeployPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [projectName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeployGet: async (projectName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deploy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (projectName !== undefined) {
                localVarQueryParameter['projectName'] = projectName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectDetailsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/projectDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeployPageApi - functional programming interface
 * @export
 */
export const DeployPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeployPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [projectName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeployGet(projectName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CloudflarePagesDeployment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeployGet(projectName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectDetailsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeployPageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectDetailsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeployPageApi - factory interface
 * @export
 */
export const DeployPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeployPageApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [projectName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeployGet(projectName?: string, options?: any): AxiosPromise<CloudflarePagesDeployment> {
            return localVarFp.apiDeployGet(projectName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectDetailsGet(options?: any): AxiosPromise<Array<DeployPageDto>> {
            return localVarFp.apiProjectDetailsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeployPageApi - object-oriented interface
 * @export
 * @class DeployPageApi
 * @extends {BaseAPI}
 */
export class DeployPageApi extends BaseAPI {
    /**
     * 
     * @param {string} [projectName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeployPageApi
     */
    public apiDeployGet(projectName?: string, options?: AxiosRequestConfig) {
        return DeployPageApiFp(this.configuration).apiDeployGet(projectName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeployPageApi
     */
    public apiProjectDetailsGet(options?: AxiosRequestConfig) {
        return DeployPageApiFp(this.configuration).apiProjectDetailsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DiscountCodeApi - axios parameter creator
 * @export
 */
export const DiscountCodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [code] 
         * @param {number} [orderAmount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDiscountCodeApplyCodeGet: async (code?: string, orderAmount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DiscountCode/applyCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (orderAmount !== undefined) {
                localVarQueryParameter['orderAmount'] = orderAmount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDiscountCodeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DiscountCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDiscountCodeIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDiscountCodeIdDelete', 'id', id)
            const localVarPath = `/api/DiscountCode/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDiscountCodeIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDiscountCodeIdGet', 'id', id)
            const localVarPath = `/api/DiscountCode/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DiscountCodeUpdateDto} [discountCodeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDiscountCodeIdPatch: async (id: number, discountCodeUpdateDto?: DiscountCodeUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDiscountCodeIdPatch', 'id', id)
            const localVarPath = `/api/DiscountCode/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discountCodeUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DiscountCodeUpdateDto} [discountCodeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDiscountCodeIdPut: async (id: number, discountCodeUpdateDto?: DiscountCodeUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDiscountCodeIdPut', 'id', id)
            const localVarPath = `/api/DiscountCode/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discountCodeUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DiscountCodeCreateDto} [discountCodeCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDiscountCodePost: async (discountCodeCreateDto?: DiscountCodeCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DiscountCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discountCodeCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscountCodeApi - functional programming interface
 * @export
 */
export const DiscountCodeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiscountCodeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [code] 
         * @param {number} [orderAmount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDiscountCodeApplyCodeGet(code?: string, orderAmount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountCodeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDiscountCodeApplyCodeGet(code, orderAmount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDiscountCodeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DiscountCodeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDiscountCodeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDiscountCodeIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDiscountCodeIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDiscountCodeIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountCodeDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDiscountCodeIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DiscountCodeUpdateDto} [discountCodeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDiscountCodeIdPatch(id: number, discountCodeUpdateDto?: DiscountCodeUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountCodeDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDiscountCodeIdPatch(id, discountCodeUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DiscountCodeUpdateDto} [discountCodeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDiscountCodeIdPut(id: number, discountCodeUpdateDto?: DiscountCodeUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountCodeDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDiscountCodeIdPut(id, discountCodeUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DiscountCodeCreateDto} [discountCodeCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDiscountCodePost(discountCodeCreateDto?: DiscountCodeCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountCodeDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDiscountCodePost(discountCodeCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DiscountCodeApi - factory interface
 * @export
 */
export const DiscountCodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiscountCodeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [code] 
         * @param {number} [orderAmount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDiscountCodeApplyCodeGet(code?: string, orderAmount?: number, options?: any): AxiosPromise<DiscountCodeResponseDto> {
            return localVarFp.apiDiscountCodeApplyCodeGet(code, orderAmount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDiscountCodeGet(options?: any): AxiosPromise<Array<DiscountCodeDto>> {
            return localVarFp.apiDiscountCodeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDiscountCodeIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiDiscountCodeIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDiscountCodeIdGet(id: number, options?: any): AxiosPromise<DiscountCodeDetailsDto> {
            return localVarFp.apiDiscountCodeIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DiscountCodeUpdateDto} [discountCodeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDiscountCodeIdPatch(id: number, discountCodeUpdateDto?: DiscountCodeUpdateDto, options?: any): AxiosPromise<DiscountCodeDetailsDto> {
            return localVarFp.apiDiscountCodeIdPatch(id, discountCodeUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DiscountCodeUpdateDto} [discountCodeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDiscountCodeIdPut(id: number, discountCodeUpdateDto?: DiscountCodeUpdateDto, options?: any): AxiosPromise<DiscountCodeDetailsDto> {
            return localVarFp.apiDiscountCodeIdPut(id, discountCodeUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DiscountCodeCreateDto} [discountCodeCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDiscountCodePost(discountCodeCreateDto?: DiscountCodeCreateDto, options?: any): AxiosPromise<DiscountCodeDetailsDto> {
            return localVarFp.apiDiscountCodePost(discountCodeCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiscountCodeApi - object-oriented interface
 * @export
 * @class DiscountCodeApi
 * @extends {BaseAPI}
 */
export class DiscountCodeApi extends BaseAPI {
    /**
     * 
     * @param {string} [code] 
     * @param {number} [orderAmount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountCodeApi
     */
    public apiDiscountCodeApplyCodeGet(code?: string, orderAmount?: number, options?: AxiosRequestConfig) {
        return DiscountCodeApiFp(this.configuration).apiDiscountCodeApplyCodeGet(code, orderAmount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountCodeApi
     */
    public apiDiscountCodeGet(options?: AxiosRequestConfig) {
        return DiscountCodeApiFp(this.configuration).apiDiscountCodeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountCodeApi
     */
    public apiDiscountCodeIdDelete(id: number, options?: AxiosRequestConfig) {
        return DiscountCodeApiFp(this.configuration).apiDiscountCodeIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountCodeApi
     */
    public apiDiscountCodeIdGet(id: number, options?: AxiosRequestConfig) {
        return DiscountCodeApiFp(this.configuration).apiDiscountCodeIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DiscountCodeUpdateDto} [discountCodeUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountCodeApi
     */
    public apiDiscountCodeIdPatch(id: number, discountCodeUpdateDto?: DiscountCodeUpdateDto, options?: AxiosRequestConfig) {
        return DiscountCodeApiFp(this.configuration).apiDiscountCodeIdPatch(id, discountCodeUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DiscountCodeUpdateDto} [discountCodeUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountCodeApi
     */
    public apiDiscountCodeIdPut(id: number, discountCodeUpdateDto?: DiscountCodeUpdateDto, options?: AxiosRequestConfig) {
        return DiscountCodeApiFp(this.configuration).apiDiscountCodeIdPut(id, discountCodeUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DiscountCodeCreateDto} [discountCodeCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountCodeApi
     */
    public apiDiscountCodePost(discountCodeCreateDto?: DiscountCodeCreateDto, options?: AxiosRequestConfig) {
        return DiscountCodeApiFp(this.configuration).apiDiscountCodePost(discountCodeCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/File`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileIdDelete', 'id', id)
            const localVarPath = `/api/File/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdFileStreamGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileIdFileStreamGet', 'id', id)
            const localVarPath = `/api/File/{id}/FileStream`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileIdGet', 'id', id)
            const localVarPath = `/api/File/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdImageGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileIdImageGet', 'id', id)
            const localVarPath = `/api/File/{id}/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FileUpdateDto} [fileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdPatch: async (id: number, fileUpdateDto?: FileUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileIdPatch', 'id', id)
            const localVarPath = `/api/File/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FileUpdateDto} [fileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdPut: async (id: number, fileUpdateDto?: FileUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileIdPut', 'id', id)
            const localVarPath = `/api/File/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilePost: async (file?: File, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/File`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        menuEnglishPdfGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/menuEnglish.pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        menuPdfGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/menu.pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileIdFileStreamGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileIdFileStreamGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileIdImageGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileIdImageGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {FileUpdateDto} [fileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileIdPatch(id: number, fileUpdateDto?: FileUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileIdPatch(id, fileUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {FileUpdateDto} [fileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileIdPut(id: number, fileUpdateDto?: FileUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileIdPut(id, fileUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilePost(file?: File, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilePost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async menuEnglishPdfGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.menuEnglishPdfGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async menuPdfGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.menuPdfGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileGet(options?: any): AxiosPromise<Array<FileDto>> {
            return localVarFp.apiFileGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdFileStreamGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileIdFileStreamGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdGet(id: number, options?: any): AxiosPromise<FileDetailsDto> {
            return localVarFp.apiFileIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdImageGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileIdImageGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {FileUpdateDto} [fileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdPatch(id: number, fileUpdateDto?: FileUpdateDto, options?: any): AxiosPromise<FileDetailsDto> {
            return localVarFp.apiFileIdPatch(id, fileUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {FileUpdateDto} [fileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdPut(id: number, fileUpdateDto?: FileUpdateDto, options?: any): AxiosPromise<FileDetailsDto> {
            return localVarFp.apiFileIdPut(id, fileUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilePost(file?: File, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<FileDetailsDto> {
            return localVarFp.apiFilePost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        menuEnglishPdfGet(options?: any): AxiosPromise<void> {
            return localVarFp.menuEnglishPdfGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        menuPdfGet(options?: any): AxiosPromise<void> {
            return localVarFp.menuPdfGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileGet(options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileIdDelete(id: number, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileIdFileStreamGet(id: number, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileIdFileStreamGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileIdGet(id: number, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileIdImageGet(id: number, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileIdImageGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {FileUpdateDto} [fileUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileIdPatch(id: number, fileUpdateDto?: FileUpdateDto, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileIdPatch(id, fileUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {FileUpdateDto} [fileUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileIdPut(id: number, fileUpdateDto?: FileUpdateDto, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileIdPut(id, fileUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFilePost(file?: File, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFilePost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public menuEnglishPdfGet(options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).menuEnglishPdfGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public menuPdfGet(options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).menuPdfGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MenuApi - axios parameter creator
 * @export
 */
export const MenuApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Menu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMenuIdDelete', 'id', id)
            const localVarPath = `/api/Menu/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMenuIdGet', 'id', id)
            const localVarPath = `/api/Menu/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MenuUpdateDto} [menuUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuIdPatch: async (id: number, menuUpdateDto?: MenuUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMenuIdPatch', 'id', id)
            const localVarPath = `/api/Menu/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(menuUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MenuUpdateDto} [menuUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuIdPut: async (id: number, menuUpdateDto?: MenuUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMenuIdPut', 'id', id)
            const localVarPath = `/api/Menu/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(menuUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MenuCreateDto} [menuCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuPost: async (menuCreateDto?: MenuCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Menu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(menuCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MenuApi - functional programming interface
 * @export
 */
export const MenuApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MenuApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MenuDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MenuUpdateDto} [menuUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuIdPatch(id: number, menuUpdateDto?: MenuUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuIdPatch(id, menuUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MenuUpdateDto} [menuUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuIdPut(id: number, menuUpdateDto?: MenuUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuIdPut(id, menuUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MenuCreateDto} [menuCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuPost(menuCreateDto?: MenuCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuPost(menuCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MenuApi - factory interface
 * @export
 */
export const MenuApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MenuApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuGet(options?: any): AxiosPromise<Array<MenuDto>> {
            return localVarFp.apiMenuGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMenuIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuIdGet(id: number, options?: any): AxiosPromise<MenuDetailsDto> {
            return localVarFp.apiMenuIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MenuUpdateDto} [menuUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuIdPatch(id: number, menuUpdateDto?: MenuUpdateDto, options?: any): AxiosPromise<MenuDetailsDto> {
            return localVarFp.apiMenuIdPatch(id, menuUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MenuUpdateDto} [menuUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuIdPut(id: number, menuUpdateDto?: MenuUpdateDto, options?: any): AxiosPromise<MenuDetailsDto> {
            return localVarFp.apiMenuIdPut(id, menuUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MenuCreateDto} [menuCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuPost(menuCreateDto?: MenuCreateDto, options?: any): AxiosPromise<MenuDetailsDto> {
            return localVarFp.apiMenuPost(menuCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MenuApi - object-oriented interface
 * @export
 * @class MenuApi
 * @extends {BaseAPI}
 */
export class MenuApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public apiMenuGet(options?: AxiosRequestConfig) {
        return MenuApiFp(this.configuration).apiMenuGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public apiMenuIdDelete(id: number, options?: AxiosRequestConfig) {
        return MenuApiFp(this.configuration).apiMenuIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public apiMenuIdGet(id: number, options?: AxiosRequestConfig) {
        return MenuApiFp(this.configuration).apiMenuIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MenuUpdateDto} [menuUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public apiMenuIdPatch(id: number, menuUpdateDto?: MenuUpdateDto, options?: AxiosRequestConfig) {
        return MenuApiFp(this.configuration).apiMenuIdPatch(id, menuUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MenuUpdateDto} [menuUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public apiMenuIdPut(id: number, menuUpdateDto?: MenuUpdateDto, options?: AxiosRequestConfig) {
        return MenuApiFp(this.configuration).apiMenuIdPut(id, menuUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MenuCreateDto} [menuCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public apiMenuPost(menuCreateDto?: MenuCreateDto, options?: AxiosRequestConfig) {
        return MenuApiFp(this.configuration).apiMenuPost(menuCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MenuCategoryApi - axios parameter creator
 * @export
 */
export const MenuCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuCategoryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MenuCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuCategoryIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMenuCategoryIdDelete', 'id', id)
            const localVarPath = `/api/MenuCategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuCategoryIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMenuCategoryIdGet', 'id', id)
            const localVarPath = `/api/MenuCategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MenuCategoryUpdateDto} [menuCategoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuCategoryIdPatch: async (id: number, menuCategoryUpdateDto?: MenuCategoryUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMenuCategoryIdPatch', 'id', id)
            const localVarPath = `/api/MenuCategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(menuCategoryUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MenuCategoryUpdateDto} [menuCategoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuCategoryIdPut: async (id: number, menuCategoryUpdateDto?: MenuCategoryUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMenuCategoryIdPut', 'id', id)
            const localVarPath = `/api/MenuCategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(menuCategoryUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuCategoryPositionIdDirectionPatch: async (id: number, direction: Direction, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMenuCategoryPositionIdDirectionPatch', 'id', id)
            // verify required parameter 'direction' is not null or undefined
            assertParamExists('apiMenuCategoryPositionIdDirectionPatch', 'direction', direction)
            const localVarPath = `/api/MenuCategory/Position/{id}/{direction}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"direction"}}`, encodeURIComponent(String(direction)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MenuCategoryCreateDto} [menuCategoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuCategoryPost: async (menuCategoryCreateDto?: MenuCategoryCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MenuCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(menuCategoryCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MenuCategoryApi - functional programming interface
 * @export
 */
export const MenuCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MenuCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuCategoryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MenuCategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuCategoryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuCategoryIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuCategoryIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuCategoryIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuCategoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuCategoryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MenuCategoryUpdateDto} [menuCategoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuCategoryIdPatch(id: number, menuCategoryUpdateDto?: MenuCategoryUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuCategoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuCategoryIdPatch(id, menuCategoryUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MenuCategoryUpdateDto} [menuCategoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuCategoryIdPut(id: number, menuCategoryUpdateDto?: MenuCategoryUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuCategoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuCategoryIdPut(id, menuCategoryUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuCategoryPositionIdDirectionPatch(id: number, direction: Direction, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MenuCategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuCategoryPositionIdDirectionPatch(id, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MenuCategoryCreateDto} [menuCategoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuCategoryPost(menuCategoryCreateDto?: MenuCategoryCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuCategoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuCategoryPost(menuCategoryCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MenuCategoryApi - factory interface
 * @export
 */
export const MenuCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MenuCategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuCategoryGet(options?: any): AxiosPromise<Array<MenuCategoryDto>> {
            return localVarFp.apiMenuCategoryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuCategoryIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMenuCategoryIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuCategoryIdGet(id: number, options?: any): AxiosPromise<MenuCategoryDetailsDto> {
            return localVarFp.apiMenuCategoryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MenuCategoryUpdateDto} [menuCategoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuCategoryIdPatch(id: number, menuCategoryUpdateDto?: MenuCategoryUpdateDto, options?: any): AxiosPromise<MenuCategoryDetailsDto> {
            return localVarFp.apiMenuCategoryIdPatch(id, menuCategoryUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MenuCategoryUpdateDto} [menuCategoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuCategoryIdPut(id: number, menuCategoryUpdateDto?: MenuCategoryUpdateDto, options?: any): AxiosPromise<MenuCategoryDetailsDto> {
            return localVarFp.apiMenuCategoryIdPut(id, menuCategoryUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuCategoryPositionIdDirectionPatch(id: number, direction: Direction, options?: any): AxiosPromise<Array<MenuCategoryDto>> {
            return localVarFp.apiMenuCategoryPositionIdDirectionPatch(id, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MenuCategoryCreateDto} [menuCategoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuCategoryPost(menuCategoryCreateDto?: MenuCategoryCreateDto, options?: any): AxiosPromise<MenuCategoryDetailsDto> {
            return localVarFp.apiMenuCategoryPost(menuCategoryCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MenuCategoryApi - object-oriented interface
 * @export
 * @class MenuCategoryApi
 * @extends {BaseAPI}
 */
export class MenuCategoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuCategoryApi
     */
    public apiMenuCategoryGet(options?: AxiosRequestConfig) {
        return MenuCategoryApiFp(this.configuration).apiMenuCategoryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuCategoryApi
     */
    public apiMenuCategoryIdDelete(id: number, options?: AxiosRequestConfig) {
        return MenuCategoryApiFp(this.configuration).apiMenuCategoryIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuCategoryApi
     */
    public apiMenuCategoryIdGet(id: number, options?: AxiosRequestConfig) {
        return MenuCategoryApiFp(this.configuration).apiMenuCategoryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MenuCategoryUpdateDto} [menuCategoryUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuCategoryApi
     */
    public apiMenuCategoryIdPatch(id: number, menuCategoryUpdateDto?: MenuCategoryUpdateDto, options?: AxiosRequestConfig) {
        return MenuCategoryApiFp(this.configuration).apiMenuCategoryIdPatch(id, menuCategoryUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MenuCategoryUpdateDto} [menuCategoryUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuCategoryApi
     */
    public apiMenuCategoryIdPut(id: number, menuCategoryUpdateDto?: MenuCategoryUpdateDto, options?: AxiosRequestConfig) {
        return MenuCategoryApiFp(this.configuration).apiMenuCategoryIdPut(id, menuCategoryUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Direction} direction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuCategoryApi
     */
    public apiMenuCategoryPositionIdDirectionPatch(id: number, direction: Direction, options?: AxiosRequestConfig) {
        return MenuCategoryApiFp(this.configuration).apiMenuCategoryPositionIdDirectionPatch(id, direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MenuCategoryCreateDto} [menuCategoryCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuCategoryApi
     */
    public apiMenuCategoryPost(menuCategoryCreateDto?: MenuCategoryCreateDto, options?: AxiosRequestConfig) {
        return MenuCategoryApiFp(this.configuration).apiMenuCategoryPost(menuCategoryCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MenuEntryApi - axios parameter creator
 * @export
 */
export const MenuEntryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuEntryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MenuEntry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuEntryIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMenuEntryIdDelete', 'id', id)
            const localVarPath = `/api/MenuEntry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuEntryIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMenuEntryIdGet', 'id', id)
            const localVarPath = `/api/MenuEntry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MenuEntryUpdateDto} [menuEntryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuEntryIdPatch: async (id: number, menuEntryUpdateDto?: MenuEntryUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMenuEntryIdPatch', 'id', id)
            const localVarPath = `/api/MenuEntry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(menuEntryUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MenuEntryUpdateDto} [menuEntryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuEntryIdPut: async (id: number, menuEntryUpdateDto?: MenuEntryUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMenuEntryIdPut', 'id', id)
            const localVarPath = `/api/MenuEntry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(menuEntryUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuEntryPositionIdDirectionPatch: async (id: number, direction: Direction, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMenuEntryPositionIdDirectionPatch', 'id', id)
            // verify required parameter 'direction' is not null or undefined
            assertParamExists('apiMenuEntryPositionIdDirectionPatch', 'direction', direction)
            const localVarPath = `/api/MenuEntry/Position/{id}/{direction}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"direction"}}`, encodeURIComponent(String(direction)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MenuEntryCreateDto} [menuEntryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuEntryPost: async (menuEntryCreateDto?: MenuEntryCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MenuEntry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(menuEntryCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MenuEntryApi - functional programming interface
 * @export
 */
export const MenuEntryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MenuEntryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuEntryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MenuEntryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuEntryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuEntryIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuEntryIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuEntryIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuEntryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuEntryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MenuEntryUpdateDto} [menuEntryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuEntryIdPatch(id: number, menuEntryUpdateDto?: MenuEntryUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuEntryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuEntryIdPatch(id, menuEntryUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MenuEntryUpdateDto} [menuEntryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuEntryIdPut(id: number, menuEntryUpdateDto?: MenuEntryUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuEntryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuEntryIdPut(id, menuEntryUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuEntryPositionIdDirectionPatch(id: number, direction: Direction, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MenuEntryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuEntryPositionIdDirectionPatch(id, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MenuEntryCreateDto} [menuEntryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuEntryPost(menuEntryCreateDto?: MenuEntryCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuEntryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuEntryPost(menuEntryCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MenuEntryApi - factory interface
 * @export
 */
export const MenuEntryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MenuEntryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuEntryGet(options?: any): AxiosPromise<Array<MenuEntryDto>> {
            return localVarFp.apiMenuEntryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuEntryIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMenuEntryIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuEntryIdGet(id: number, options?: any): AxiosPromise<MenuEntryDetailsDto> {
            return localVarFp.apiMenuEntryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MenuEntryUpdateDto} [menuEntryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuEntryIdPatch(id: number, menuEntryUpdateDto?: MenuEntryUpdateDto, options?: any): AxiosPromise<MenuEntryDetailsDto> {
            return localVarFp.apiMenuEntryIdPatch(id, menuEntryUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MenuEntryUpdateDto} [menuEntryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuEntryIdPut(id: number, menuEntryUpdateDto?: MenuEntryUpdateDto, options?: any): AxiosPromise<MenuEntryDetailsDto> {
            return localVarFp.apiMenuEntryIdPut(id, menuEntryUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuEntryPositionIdDirectionPatch(id: number, direction: Direction, options?: any): AxiosPromise<Array<MenuEntryDto>> {
            return localVarFp.apiMenuEntryPositionIdDirectionPatch(id, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MenuEntryCreateDto} [menuEntryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuEntryPost(menuEntryCreateDto?: MenuEntryCreateDto, options?: any): AxiosPromise<MenuEntryDetailsDto> {
            return localVarFp.apiMenuEntryPost(menuEntryCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MenuEntryApi - object-oriented interface
 * @export
 * @class MenuEntryApi
 * @extends {BaseAPI}
 */
export class MenuEntryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEntryApi
     */
    public apiMenuEntryGet(options?: AxiosRequestConfig) {
        return MenuEntryApiFp(this.configuration).apiMenuEntryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEntryApi
     */
    public apiMenuEntryIdDelete(id: number, options?: AxiosRequestConfig) {
        return MenuEntryApiFp(this.configuration).apiMenuEntryIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEntryApi
     */
    public apiMenuEntryIdGet(id: number, options?: AxiosRequestConfig) {
        return MenuEntryApiFp(this.configuration).apiMenuEntryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MenuEntryUpdateDto} [menuEntryUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEntryApi
     */
    public apiMenuEntryIdPatch(id: number, menuEntryUpdateDto?: MenuEntryUpdateDto, options?: AxiosRequestConfig) {
        return MenuEntryApiFp(this.configuration).apiMenuEntryIdPatch(id, menuEntryUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MenuEntryUpdateDto} [menuEntryUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEntryApi
     */
    public apiMenuEntryIdPut(id: number, menuEntryUpdateDto?: MenuEntryUpdateDto, options?: AxiosRequestConfig) {
        return MenuEntryApiFp(this.configuration).apiMenuEntryIdPut(id, menuEntryUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Direction} direction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEntryApi
     */
    public apiMenuEntryPositionIdDirectionPatch(id: number, direction: Direction, options?: AxiosRequestConfig) {
        return MenuEntryApiFp(this.configuration).apiMenuEntryPositionIdDirectionPatch(id, direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MenuEntryCreateDto} [menuEntryCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEntryApi
     */
    public apiMenuEntryPost(menuEntryCreateDto?: MenuEntryCreateDto, options?: AxiosRequestConfig) {
        return MenuEntryApiFp(this.configuration).apiMenuEntryPost(menuEntryCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewsSectionApi - axios parameter creator
 * @export
 */
export const NewsSectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsSectionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/NewsSection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsSectionIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsSectionIdDelete', 'id', id)
            const localVarPath = `/api/NewsSection/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsSectionIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsSectionIdGet', 'id', id)
            const localVarPath = `/api/NewsSection/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {NewsSectionUpdateDto} [newsSectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsSectionIdPatch: async (id: number, newsSectionUpdateDto?: NewsSectionUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsSectionIdPatch', 'id', id)
            const localVarPath = `/api/NewsSection/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newsSectionUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {NewsSectionUpdateDto} [newsSectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsSectionIdPut: async (id: number, newsSectionUpdateDto?: NewsSectionUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsSectionIdPut', 'id', id)
            const localVarPath = `/api/NewsSection/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newsSectionUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewsSectionCreateDto} [newsSectionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsSectionPost: async (newsSectionCreateDto?: NewsSectionCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/NewsSection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newsSectionCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsSectionApi - functional programming interface
 * @export
 */
export const NewsSectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsSectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsSectionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewsSectionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsSectionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsSectionIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsSectionIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsSectionIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsSectionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsSectionIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {NewsSectionUpdateDto} [newsSectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsSectionIdPatch(id: number, newsSectionUpdateDto?: NewsSectionUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsSectionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsSectionIdPatch(id, newsSectionUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {NewsSectionUpdateDto} [newsSectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsSectionIdPut(id: number, newsSectionUpdateDto?: NewsSectionUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsSectionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsSectionIdPut(id, newsSectionUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewsSectionCreateDto} [newsSectionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsSectionPost(newsSectionCreateDto?: NewsSectionCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsSectionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsSectionPost(newsSectionCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsSectionApi - factory interface
 * @export
 */
export const NewsSectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsSectionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsSectionGet(options?: any): AxiosPromise<Array<NewsSectionDto>> {
            return localVarFp.apiNewsSectionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsSectionIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiNewsSectionIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsSectionIdGet(id: number, options?: any): AxiosPromise<NewsSectionDetailsDto> {
            return localVarFp.apiNewsSectionIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {NewsSectionUpdateDto} [newsSectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsSectionIdPatch(id: number, newsSectionUpdateDto?: NewsSectionUpdateDto, options?: any): AxiosPromise<NewsSectionDetailsDto> {
            return localVarFp.apiNewsSectionIdPatch(id, newsSectionUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {NewsSectionUpdateDto} [newsSectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsSectionIdPut(id: number, newsSectionUpdateDto?: NewsSectionUpdateDto, options?: any): AxiosPromise<NewsSectionDetailsDto> {
            return localVarFp.apiNewsSectionIdPut(id, newsSectionUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewsSectionCreateDto} [newsSectionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsSectionPost(newsSectionCreateDto?: NewsSectionCreateDto, options?: any): AxiosPromise<NewsSectionDetailsDto> {
            return localVarFp.apiNewsSectionPost(newsSectionCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsSectionApi - object-oriented interface
 * @export
 * @class NewsSectionApi
 * @extends {BaseAPI}
 */
export class NewsSectionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsSectionApi
     */
    public apiNewsSectionGet(options?: AxiosRequestConfig) {
        return NewsSectionApiFp(this.configuration).apiNewsSectionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsSectionApi
     */
    public apiNewsSectionIdDelete(id: number, options?: AxiosRequestConfig) {
        return NewsSectionApiFp(this.configuration).apiNewsSectionIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsSectionApi
     */
    public apiNewsSectionIdGet(id: number, options?: AxiosRequestConfig) {
        return NewsSectionApiFp(this.configuration).apiNewsSectionIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {NewsSectionUpdateDto} [newsSectionUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsSectionApi
     */
    public apiNewsSectionIdPatch(id: number, newsSectionUpdateDto?: NewsSectionUpdateDto, options?: AxiosRequestConfig) {
        return NewsSectionApiFp(this.configuration).apiNewsSectionIdPatch(id, newsSectionUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {NewsSectionUpdateDto} [newsSectionUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsSectionApi
     */
    public apiNewsSectionIdPut(id: number, newsSectionUpdateDto?: NewsSectionUpdateDto, options?: AxiosRequestConfig) {
        return NewsSectionApiFp(this.configuration).apiNewsSectionIdPut(id, newsSectionUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewsSectionCreateDto} [newsSectionCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsSectionApi
     */
    public apiNewsSectionPost(newsSectionCreateDto?: NewsSectionCreateDto, options?: AxiosRequestConfig) {
        return NewsSectionApiFp(this.configuration).apiNewsSectionPost(newsSectionCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [orderGuid] 
         * @param {number} [discountCodeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderApplyCodePatch: async (orderGuid?: string, discountCodeId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Order/applyCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (orderGuid !== undefined) {
                localVarQueryParameter['orderGuid'] = orderGuid;
            }

            if (discountCodeId !== undefined) {
                localVarQueryParameter['discountCodeId'] = discountCodeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderAvailablePaymentMethodsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Order/availablePaymentMethods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {OrderSortDirections} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderCompletedOrdersGet: async (page?: number, sortDirection?: OrderSortDirections, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Order/CompletedOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderGuid] 
         * @param {OrderStage} [orderStage] 
         * @param {string} [parcelSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderConfirmOrderPatch: async (orderGuid?: string, orderStage?: OrderStage, parcelSize?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Order/confirmOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (orderGuid !== undefined) {
                localVarQueryParameter['orderGuid'] = orderGuid;
            }

            if (orderStage !== undefined) {
                localVarQueryParameter['orderStage'] = orderStage;
            }

            if (parcelSize !== undefined) {
                localVarQueryParameter['parcelSize'] = parcelSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderDeliveryCostGet: async (orderGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Order/deliveryCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderGuid !== undefined) {
                localVarQueryParameter['orderGuid'] = orderGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrderIdDelete', 'id', id)
            const localVarPath = `/api/Order/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrderIdGet', 'id', id)
            const localVarPath = `/api/Order/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderUpdateDto} [orderUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderIdPatch: async (id: number, orderUpdateDto?: OrderUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrderIdPatch', 'id', id)
            const localVarPath = `/api/Order/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderUpdateDto} [orderUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderIdPut: async (id: number, orderUpdateDto?: OrderUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrderIdPut', 'id', id)
            const localVarPath = `/api/Order/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {OrderSortDirections} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderInCompletedOrdersGet: async (page?: number, sortDirection?: OrderSortDirections, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Order/InCompletedOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderMinimalGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Order/minimal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderOrderGuidDetailsGet: async (orderGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderGuid' is not null or undefined
            assertParamExists('apiOrderOrderGuidDetailsGet', 'orderGuid', orderGuid)
            const localVarPath = `/api/Order/{orderGuid}/details`
                .replace(`{${"orderGuid"}}`, encodeURIComponent(String(orderGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderOrderGuidDetailsMinimalGet: async (orderGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderGuid' is not null or undefined
            assertParamExists('apiOrderOrderGuidDetailsMinimalGet', 'orderGuid', orderGuid)
            const localVarPath = `/api/Order/{orderGuid}/detailsMinimal`
                .replace(`{${"orderGuid"}}`, encodeURIComponent(String(orderGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderGuid 
         * @param {OrderUpdateDto} [orderUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderOrderGuidFillOrderClientDetailsPut: async (orderGuid: string, orderUpdateDto?: OrderUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderGuid' is not null or undefined
            assertParamExists('apiOrderOrderGuidFillOrderClientDetailsPut', 'orderGuid', orderGuid)
            const localVarPath = `/api/Order/{orderGuid}/fillOrderClientDetails`
                .replace(`{${"orderGuid"}}`, encodeURIComponent(String(orderGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderGuid 
         * @param {OrderUpdateDto} [orderUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderOrderGuidFillOrderDeliveryDetailsPut: async (orderGuid: string, orderUpdateDto?: OrderUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderGuid' is not null or undefined
            assertParamExists('apiOrderOrderGuidFillOrderDeliveryDetailsPut', 'orderGuid', orderGuid)
            const localVarPath = `/api/Order/{orderGuid}/fillOrderDeliveryDetails`
                .replace(`{${"orderGuid"}}`, encodeURIComponent(String(orderGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderGuid 
         * @param {OrderUpdateDto} [orderUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderOrderGuidFillOrderPaymentDetailsPut: async (orderGuid: string, orderUpdateDto?: OrderUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderGuid' is not null or undefined
            assertParamExists('apiOrderOrderGuidFillOrderPaymentDetailsPut', 'orderGuid', orderGuid)
            const localVarPath = `/api/Order/{orderGuid}/fillOrderPaymentDetails`
                .replace(`{${"orderGuid"}}`, encodeURIComponent(String(orderGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {OrderSortDirections} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderOrdersGet: async (page?: number, sortDirection?: OrderSortDirections, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Order/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderPlaceDto} [orderPlaceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderPlaceOrderPost: async (orderPlaceDto?: OrderPlaceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Order/placeOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderPlaceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderCreateDto} [orderCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderPost: async (orderCreateDto?: OrderCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [shipmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderShipmentLabelGet: async (shipmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Order/shipmentLabel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (shipmentId !== undefined) {
                localVarQueryParameter['shipmentId'] = shipmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderGuid] 
         * @param {OrderStage} [orderStage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderStatePatch: async (orderGuid?: string, orderStage?: OrderStage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Order/state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderGuid !== undefined) {
                localVarQueryParameter['orderGuid'] = orderGuid;
            }

            if (orderStage !== undefined) {
                localVarQueryParameter['orderStage'] = orderStage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [orderGuid] 
         * @param {number} [discountCodeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderApplyCodePatch(orderGuid?: string, discountCodeId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountCodeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderApplyCodePatch(orderGuid, discountCodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderAvailablePaymentMethodsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayUAvailablePayMethods>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderAvailablePaymentMethodsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {OrderSortDirections} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderCompletedOrdersGet(page?: number, sortDirection?: OrderSortDirections, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderCompletedOrdersGet(page, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [orderGuid] 
         * @param {OrderStage} [orderStage] 
         * @param {string} [parcelSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderConfirmOrderPatch(orderGuid?: string, orderStage?: OrderStage, parcelSize?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderConfirmOrderPatch(orderGuid, orderStage, parcelSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [orderGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderDeliveryCostGet(orderGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDeliveryVariantCostDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderDeliveryCostGet(orderGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderUpdateDto} [orderUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderIdPatch(id: number, orderUpdateDto?: OrderUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderIdPatch(id, orderUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderUpdateDto} [orderUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderIdPut(id: number, orderUpdateDto?: OrderUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderIdPut(id, orderUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {OrderSortDirections} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderInCompletedOrdersGet(page?: number, sortDirection?: OrderSortDirections, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderInCompletedOrdersGet(page, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderMinimalGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderMinimalDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderMinimalGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderOrderGuidDetailsGet(orderGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderOrderGuidDetailsGet(orderGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderOrderGuidDetailsMinimalGet(orderGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderMinimalDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderOrderGuidDetailsMinimalGet(orderGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderGuid 
         * @param {OrderUpdateDto} [orderUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderOrderGuidFillOrderClientDetailsPut(orderGuid: string, orderUpdateDto?: OrderUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderOrderGuidFillOrderClientDetailsPut(orderGuid, orderUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderGuid 
         * @param {OrderUpdateDto} [orderUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderOrderGuidFillOrderDeliveryDetailsPut(orderGuid: string, orderUpdateDto?: OrderUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderOrderGuidFillOrderDeliveryDetailsPut(orderGuid, orderUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderGuid 
         * @param {OrderUpdateDto} [orderUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderOrderGuidFillOrderPaymentDetailsPut(orderGuid: string, orderUpdateDto?: OrderUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderOrderGuidFillOrderPaymentDetailsPut(orderGuid, orderUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {OrderSortDirections} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderOrdersGet(page?: number, sortDirection?: OrderSortDirections, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderOrdersGet(page, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrderPlaceDto} [orderPlaceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderPlaceOrderPost(orderPlaceDto?: OrderPlaceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderPlaceOrderPost(orderPlaceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrderCreateDto} [orderCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderPost(orderCreateDto?: OrderCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderPost(orderCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [shipmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderShipmentLabelGet(shipmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderShipmentLabelGet(shipmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [orderGuid] 
         * @param {OrderStage} [orderStage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderStatePatch(orderGuid?: string, orderStage?: OrderStage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCreateResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderStatePatch(orderGuid, orderStage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [orderGuid] 
         * @param {number} [discountCodeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderApplyCodePatch(orderGuid?: string, discountCodeId?: number, options?: any): AxiosPromise<DiscountCodeResponseDto> {
            return localVarFp.apiOrderApplyCodePatch(orderGuid, discountCodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderAvailablePaymentMethodsGet(options?: any): AxiosPromise<PayUAvailablePayMethods> {
            return localVarFp.apiOrderAvailablePaymentMethodsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {OrderSortDirections} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderCompletedOrdersGet(page?: number, sortDirection?: OrderSortDirections, options?: any): AxiosPromise<Array<OrderDto>> {
            return localVarFp.apiOrderCompletedOrdersGet(page, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderGuid] 
         * @param {OrderStage} [orderStage] 
         * @param {string} [parcelSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderConfirmOrderPatch(orderGuid?: string, orderStage?: OrderStage, parcelSize?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrderConfirmOrderPatch(orderGuid, orderStage, parcelSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderDeliveryCostGet(orderGuid?: string, options?: any): AxiosPromise<Array<OrderDeliveryVariantCostDto>> {
            return localVarFp.apiOrderDeliveryCostGet(orderGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderGet(options?: any): AxiosPromise<Array<OrderDto>> {
            return localVarFp.apiOrderGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrderIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderIdGet(id: number, options?: any): AxiosPromise<OrderDetailsDto> {
            return localVarFp.apiOrderIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderUpdateDto} [orderUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderIdPatch(id: number, orderUpdateDto?: OrderUpdateDto, options?: any): AxiosPromise<OrderDetailsDto> {
            return localVarFp.apiOrderIdPatch(id, orderUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderUpdateDto} [orderUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderIdPut(id: number, orderUpdateDto?: OrderUpdateDto, options?: any): AxiosPromise<OrderDetailsDto> {
            return localVarFp.apiOrderIdPut(id, orderUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {OrderSortDirections} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderInCompletedOrdersGet(page?: number, sortDirection?: OrderSortDirections, options?: any): AxiosPromise<Array<OrderDto>> {
            return localVarFp.apiOrderInCompletedOrdersGet(page, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderMinimalGet(options?: any): AxiosPromise<Array<OrderMinimalDto>> {
            return localVarFp.apiOrderMinimalGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderOrderGuidDetailsGet(orderGuid: string, options?: any): AxiosPromise<OrderDetailsDto> {
            return localVarFp.apiOrderOrderGuidDetailsGet(orderGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderOrderGuidDetailsMinimalGet(orderGuid: string, options?: any): AxiosPromise<OrderMinimalDto> {
            return localVarFp.apiOrderOrderGuidDetailsMinimalGet(orderGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderGuid 
         * @param {OrderUpdateDto} [orderUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderOrderGuidFillOrderClientDetailsPut(orderGuid: string, orderUpdateDto?: OrderUpdateDto, options?: any): AxiosPromise<OrderDto> {
            return localVarFp.apiOrderOrderGuidFillOrderClientDetailsPut(orderGuid, orderUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderGuid 
         * @param {OrderUpdateDto} [orderUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderOrderGuidFillOrderDeliveryDetailsPut(orderGuid: string, orderUpdateDto?: OrderUpdateDto, options?: any): AxiosPromise<OrderDto> {
            return localVarFp.apiOrderOrderGuidFillOrderDeliveryDetailsPut(orderGuid, orderUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderGuid 
         * @param {OrderUpdateDto} [orderUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderOrderGuidFillOrderPaymentDetailsPut(orderGuid: string, orderUpdateDto?: OrderUpdateDto, options?: any): AxiosPromise<OrderDto> {
            return localVarFp.apiOrderOrderGuidFillOrderPaymentDetailsPut(orderGuid, orderUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {OrderSortDirections} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderOrdersGet(page?: number, sortDirection?: OrderSortDirections, options?: any): AxiosPromise<Array<OrderDto>> {
            return localVarFp.apiOrderOrdersGet(page, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrderPlaceDto} [orderPlaceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderPlaceOrderPost(orderPlaceDto?: OrderPlaceDto, options?: any): AxiosPromise<OrderDetailsDto> {
            return localVarFp.apiOrderPlaceOrderPost(orderPlaceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrderCreateDto} [orderCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderPost(orderCreateDto?: OrderCreateDto, options?: any): AxiosPromise<OrderDetailsDto> {
            return localVarFp.apiOrderPost(orderCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [shipmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderShipmentLabelGet(shipmentId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrderShipmentLabelGet(shipmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderGuid] 
         * @param {OrderStage} [orderStage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderStatePatch(orderGuid?: string, orderStage?: OrderStage, options?: any): AxiosPromise<OrderCreateResultDto> {
            return localVarFp.apiOrderStatePatch(orderGuid, orderStage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @param {string} [orderGuid] 
     * @param {number} [discountCodeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderApplyCodePatch(orderGuid?: string, discountCodeId?: number, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderApplyCodePatch(orderGuid, discountCodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderAvailablePaymentMethodsGet(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderAvailablePaymentMethodsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {OrderSortDirections} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderCompletedOrdersGet(page?: number, sortDirection?: OrderSortDirections, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderCompletedOrdersGet(page, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [orderGuid] 
     * @param {OrderStage} [orderStage] 
     * @param {string} [parcelSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderConfirmOrderPatch(orderGuid?: string, orderStage?: OrderStage, parcelSize?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderConfirmOrderPatch(orderGuid, orderStage, parcelSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [orderGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderDeliveryCostGet(orderGuid?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderDeliveryCostGet(orderGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderGet(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderIdDelete(id: number, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderIdGet(id: number, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OrderUpdateDto} [orderUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderIdPatch(id: number, orderUpdateDto?: OrderUpdateDto, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderIdPatch(id, orderUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OrderUpdateDto} [orderUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderIdPut(id: number, orderUpdateDto?: OrderUpdateDto, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderIdPut(id, orderUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {OrderSortDirections} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderInCompletedOrdersGet(page?: number, sortDirection?: OrderSortDirections, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderInCompletedOrdersGet(page, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderMinimalGet(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderMinimalGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderOrderGuidDetailsGet(orderGuid: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderOrderGuidDetailsGet(orderGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderOrderGuidDetailsMinimalGet(orderGuid: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderOrderGuidDetailsMinimalGet(orderGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderGuid 
     * @param {OrderUpdateDto} [orderUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderOrderGuidFillOrderClientDetailsPut(orderGuid: string, orderUpdateDto?: OrderUpdateDto, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderOrderGuidFillOrderClientDetailsPut(orderGuid, orderUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderGuid 
     * @param {OrderUpdateDto} [orderUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderOrderGuidFillOrderDeliveryDetailsPut(orderGuid: string, orderUpdateDto?: OrderUpdateDto, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderOrderGuidFillOrderDeliveryDetailsPut(orderGuid, orderUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderGuid 
     * @param {OrderUpdateDto} [orderUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderOrderGuidFillOrderPaymentDetailsPut(orderGuid: string, orderUpdateDto?: OrderUpdateDto, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderOrderGuidFillOrderPaymentDetailsPut(orderGuid, orderUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {OrderSortDirections} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderOrdersGet(page?: number, sortDirection?: OrderSortDirections, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderOrdersGet(page, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderPlaceDto} [orderPlaceDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderPlaceOrderPost(orderPlaceDto?: OrderPlaceDto, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderPlaceOrderPost(orderPlaceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderCreateDto} [orderCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderPost(orderCreateDto?: OrderCreateDto, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderPost(orderCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [shipmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderShipmentLabelGet(shipmentId?: number, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderShipmentLabelGet(shipmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [orderGuid] 
     * @param {OrderStage} [orderStage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderStatePatch(orderGuid?: string, orderStage?: OrderStage, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiOrderStatePatch(orderGuid, orderStage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderProductApi - axios parameter creator
 * @export
 */
export const OrderProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderProductGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrderProduct`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderProductIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrderProductIdDelete', 'id', id)
            const localVarPath = `/api/OrderProduct/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderProductIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrderProductIdGet', 'id', id)
            const localVarPath = `/api/OrderProduct/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderProductUpdateDto} [orderProductUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderProductIdPatch: async (id: number, orderProductUpdateDto?: OrderProductUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrderProductIdPatch', 'id', id)
            const localVarPath = `/api/OrderProduct/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderProductUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderProductUpdateDto} [orderProductUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderProductIdPut: async (id: number, orderProductUpdateDto?: OrderProductUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrderProductIdPut', 'id', id)
            const localVarPath = `/api/OrderProduct/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderProductUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderProductOrderProductsGet: async (orderId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrderProduct/orderProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderProductCreateDto} [orderProductCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderProductPost: async (orderProductCreateDto?: OrderProductCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrderProduct`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderProductCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderProductStockUpdateDto} [orderProductStockUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderProductUpdateStockPatch: async (orderProductStockUpdateDto?: OrderProductStockUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrderProduct/updateStock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderProductStockUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderProductApi - functional programming interface
 * @export
 */
export const OrderProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderProductGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderProductDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderProductGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderProductIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderProductIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderProductIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderProductIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderProductUpdateDto} [orderProductUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderProductIdPatch(id: number, orderProductUpdateDto?: OrderProductUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderProductIdPatch(id, orderProductUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderProductUpdateDto} [orderProductUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderProductIdPut(id: number, orderProductUpdateDto?: OrderProductUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderProductIdPut(id, orderProductUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderProductOrderProductsGet(orderId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderProductDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderProductOrderProductsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrderProductCreateDto} [orderProductCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderProductPost(orderProductCreateDto?: OrderProductCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderProductPost(orderProductCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrderProductStockUpdateDto} [orderProductStockUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderProductUpdateStockPatch(orderProductStockUpdateDto?: OrderProductStockUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderProductUpdateStockPatch(orderProductStockUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderProductApi - factory interface
 * @export
 */
export const OrderProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderProductApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderProductGet(options?: any): AxiosPromise<Array<OrderProductDto>> {
            return localVarFp.apiOrderProductGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderProductIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrderProductIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderProductIdGet(id: number, options?: any): AxiosPromise<OrderProductDetailsDto> {
            return localVarFp.apiOrderProductIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderProductUpdateDto} [orderProductUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderProductIdPatch(id: number, orderProductUpdateDto?: OrderProductUpdateDto, options?: any): AxiosPromise<OrderProductDetailsDto> {
            return localVarFp.apiOrderProductIdPatch(id, orderProductUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderProductUpdateDto} [orderProductUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderProductIdPut(id: number, orderProductUpdateDto?: OrderProductUpdateDto, options?: any): AxiosPromise<OrderProductDetailsDto> {
            return localVarFp.apiOrderProductIdPut(id, orderProductUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderProductOrderProductsGet(orderId?: number, options?: any): AxiosPromise<Array<OrderProductDto>> {
            return localVarFp.apiOrderProductOrderProductsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrderProductCreateDto} [orderProductCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderProductPost(orderProductCreateDto?: OrderProductCreateDto, options?: any): AxiosPromise<OrderProductDetailsDto> {
            return localVarFp.apiOrderProductPost(orderProductCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrderProductStockUpdateDto} [orderProductStockUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderProductUpdateStockPatch(orderProductStockUpdateDto?: OrderProductStockUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrderProductUpdateStockPatch(orderProductStockUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderProductApi - object-oriented interface
 * @export
 * @class OrderProductApi
 * @extends {BaseAPI}
 */
export class OrderProductApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public apiOrderProductGet(options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).apiOrderProductGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public apiOrderProductIdDelete(id: number, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).apiOrderProductIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public apiOrderProductIdGet(id: number, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).apiOrderProductIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OrderProductUpdateDto} [orderProductUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public apiOrderProductIdPatch(id: number, orderProductUpdateDto?: OrderProductUpdateDto, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).apiOrderProductIdPatch(id, orderProductUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OrderProductUpdateDto} [orderProductUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public apiOrderProductIdPut(id: number, orderProductUpdateDto?: OrderProductUpdateDto, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).apiOrderProductIdPut(id, orderProductUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [orderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public apiOrderProductOrderProductsGet(orderId?: number, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).apiOrderProductOrderProductsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderProductCreateDto} [orderProductCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public apiOrderProductPost(orderProductCreateDto?: OrderProductCreateDto, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).apiOrderProductPost(orderProductCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderProductStockUpdateDto} [orderProductStockUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public apiOrderProductUpdateStockPatch(orderProductStockUpdateDto?: OrderProductStockUpdateDto, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).apiOrderProductUpdateStockPatch(orderProductStockUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderRefundApi - axios parameter creator
 * @export
 */
export const OrderRefundApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRefundGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrderRefund`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRefundIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrderRefundIdDelete', 'id', id)
            const localVarPath = `/api/OrderRefund/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRefundIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrderRefundIdGet', 'id', id)
            const localVarPath = `/api/OrderRefund/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderRefundUpdateDto} [orderRefundUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRefundIdPatch: async (id: number, orderRefundUpdateDto?: OrderRefundUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrderRefundIdPatch', 'id', id)
            const localVarPath = `/api/OrderRefund/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderRefundUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderRefundUpdateDto} [orderRefundUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRefundIdPut: async (id: number, orderRefundUpdateDto?: OrderRefundUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrderRefundIdPut', 'id', id)
            const localVarPath = `/api/OrderRefund/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderRefundUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderRefundCreateDto} [orderRefundCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRefundPost: async (orderRefundCreateDto?: OrderRefundCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrderRefund`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderRefundCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRefundRefundsForOrderGet: async (orderGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrderRefund/refundsForOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (orderGuid !== undefined) {
                localVarQueryParameter['orderGuid'] = orderGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderRefundApi - functional programming interface
 * @export
 */
export const OrderRefundApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderRefundApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderRefundGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderRefundDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderRefundGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderRefundIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderRefundIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderRefundIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRefundDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderRefundIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderRefundUpdateDto} [orderRefundUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderRefundIdPatch(id: number, orderRefundUpdateDto?: OrderRefundUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRefundDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderRefundIdPatch(id, orderRefundUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderRefundUpdateDto} [orderRefundUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderRefundIdPut(id: number, orderRefundUpdateDto?: OrderRefundUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRefundDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderRefundIdPut(id, orderRefundUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrderRefundCreateDto} [orderRefundCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderRefundPost(orderRefundCreateDto?: OrderRefundCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRefundDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderRefundPost(orderRefundCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [orderGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderRefundRefundsForOrderGet(orderGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderRefundDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderRefundRefundsForOrderGet(orderGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderRefundApi - factory interface
 * @export
 */
export const OrderRefundApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderRefundApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRefundGet(options?: any): AxiosPromise<Array<OrderRefundDto>> {
            return localVarFp.apiOrderRefundGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRefundIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrderRefundIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRefundIdGet(id: number, options?: any): AxiosPromise<OrderRefundDetailsDto> {
            return localVarFp.apiOrderRefundIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderRefundUpdateDto} [orderRefundUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRefundIdPatch(id: number, orderRefundUpdateDto?: OrderRefundUpdateDto, options?: any): AxiosPromise<OrderRefundDetailsDto> {
            return localVarFp.apiOrderRefundIdPatch(id, orderRefundUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderRefundUpdateDto} [orderRefundUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRefundIdPut(id: number, orderRefundUpdateDto?: OrderRefundUpdateDto, options?: any): AxiosPromise<OrderRefundDetailsDto> {
            return localVarFp.apiOrderRefundIdPut(id, orderRefundUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrderRefundCreateDto} [orderRefundCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRefundPost(orderRefundCreateDto?: OrderRefundCreateDto, options?: any): AxiosPromise<OrderRefundDetailsDto> {
            return localVarFp.apiOrderRefundPost(orderRefundCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderRefundRefundsForOrderGet(orderGuid?: string, options?: any): AxiosPromise<Array<OrderRefundDto>> {
            return localVarFp.apiOrderRefundRefundsForOrderGet(orderGuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderRefundApi - object-oriented interface
 * @export
 * @class OrderRefundApi
 * @extends {BaseAPI}
 */
export class OrderRefundApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderRefundApi
     */
    public apiOrderRefundGet(options?: AxiosRequestConfig) {
        return OrderRefundApiFp(this.configuration).apiOrderRefundGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderRefundApi
     */
    public apiOrderRefundIdDelete(id: number, options?: AxiosRequestConfig) {
        return OrderRefundApiFp(this.configuration).apiOrderRefundIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderRefundApi
     */
    public apiOrderRefundIdGet(id: number, options?: AxiosRequestConfig) {
        return OrderRefundApiFp(this.configuration).apiOrderRefundIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OrderRefundUpdateDto} [orderRefundUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderRefundApi
     */
    public apiOrderRefundIdPatch(id: number, orderRefundUpdateDto?: OrderRefundUpdateDto, options?: AxiosRequestConfig) {
        return OrderRefundApiFp(this.configuration).apiOrderRefundIdPatch(id, orderRefundUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OrderRefundUpdateDto} [orderRefundUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderRefundApi
     */
    public apiOrderRefundIdPut(id: number, orderRefundUpdateDto?: OrderRefundUpdateDto, options?: AxiosRequestConfig) {
        return OrderRefundApiFp(this.configuration).apiOrderRefundIdPut(id, orderRefundUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderRefundCreateDto} [orderRefundCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderRefundApi
     */
    public apiOrderRefundPost(orderRefundCreateDto?: OrderRefundCreateDto, options?: AxiosRequestConfig) {
        return OrderRefundApiFp(this.configuration).apiOrderRefundPost(orderRefundCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [orderGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderRefundApi
     */
    public apiOrderRefundRefundsForOrderGet(orderGuid?: string, options?: AxiosRequestConfig) {
        return OrderRefundApiFp(this.configuration).apiOrderRefundRefundsForOrderGet(orderGuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PayUNotificationApi - axios parameter creator
 * @export
 */
export const PayUNotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PayUOrderNotification} [payUOrderNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPayUNotificationPost: async (payUOrderNotification?: PayUOrderNotification, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PayUNotification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payUOrderNotification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PayUNotificationApi - functional programming interface
 * @export
 */
export const PayUNotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PayUNotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PayUOrderNotification} [payUOrderNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPayUNotificationPost(payUOrderNotification?: PayUOrderNotification, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPayUNotificationPost(payUOrderNotification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PayUNotificationApi - factory interface
 * @export
 */
export const PayUNotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PayUNotificationApiFp(configuration)
    return {
        /**
         * 
         * @param {PayUOrderNotification} [payUOrderNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPayUNotificationPost(payUOrderNotification?: PayUOrderNotification, options?: any): AxiosPromise<void> {
            return localVarFp.apiPayUNotificationPost(payUOrderNotification, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PayUNotificationApi - object-oriented interface
 * @export
 * @class PayUNotificationApi
 * @extends {BaseAPI}
 */
export class PayUNotificationApi extends BaseAPI {
    /**
     * 
     * @param {PayUOrderNotification} [payUOrderNotification] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayUNotificationApi
     */
    public apiPayUNotificationPost(payUOrderNotification?: PayUOrderNotification, options?: AxiosRequestConfig) {
        return PayUNotificationApiFp(this.configuration).apiPayUNotificationPost(payUOrderNotification, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PriceApi - axios parameter creator
 * @export
 */
export const PriceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPriceGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPriceIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPriceIdDelete', 'id', id)
            const localVarPath = `/api/Price/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPriceIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPriceIdGet', 'id', id)
            const localVarPath = `/api/Price/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PriceUpdateDto} [priceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPriceIdPatch: async (id: number, priceUpdateDto?: PriceUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPriceIdPatch', 'id', id)
            const localVarPath = `/api/Price/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(priceUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PriceUpdateDto} [priceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPriceIdPut: async (id: number, priceUpdateDto?: PriceUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPriceIdPut', 'id', id)
            const localVarPath = `/api/Price/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(priceUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PriceCreateDto} [priceCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPricePost: async (priceCreateDto?: PriceCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(priceCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PriceApi - functional programming interface
 * @export
 */
export const PriceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PriceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPriceGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PriceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPriceGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPriceIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPriceIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPriceIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPriceIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PriceUpdateDto} [priceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPriceIdPatch(id: number, priceUpdateDto?: PriceUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPriceIdPatch(id, priceUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PriceUpdateDto} [priceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPriceIdPut(id: number, priceUpdateDto?: PriceUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPriceIdPut(id, priceUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PriceCreateDto} [priceCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPricePost(priceCreateDto?: PriceCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPricePost(priceCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PriceApi - factory interface
 * @export
 */
export const PriceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PriceApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPriceGet(options?: any): AxiosPromise<Array<PriceDto>> {
            return localVarFp.apiPriceGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPriceIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiPriceIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPriceIdGet(id: number, options?: any): AxiosPromise<PriceDetailsDto> {
            return localVarFp.apiPriceIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PriceUpdateDto} [priceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPriceIdPatch(id: number, priceUpdateDto?: PriceUpdateDto, options?: any): AxiosPromise<PriceDetailsDto> {
            return localVarFp.apiPriceIdPatch(id, priceUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PriceUpdateDto} [priceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPriceIdPut(id: number, priceUpdateDto?: PriceUpdateDto, options?: any): AxiosPromise<PriceDetailsDto> {
            return localVarFp.apiPriceIdPut(id, priceUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PriceCreateDto} [priceCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPricePost(priceCreateDto?: PriceCreateDto, options?: any): AxiosPromise<PriceDetailsDto> {
            return localVarFp.apiPricePost(priceCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PriceApi - object-oriented interface
 * @export
 * @class PriceApi
 * @extends {BaseAPI}
 */
export class PriceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceApi
     */
    public apiPriceGet(options?: AxiosRequestConfig) {
        return PriceApiFp(this.configuration).apiPriceGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceApi
     */
    public apiPriceIdDelete(id: number, options?: AxiosRequestConfig) {
        return PriceApiFp(this.configuration).apiPriceIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceApi
     */
    public apiPriceIdGet(id: number, options?: AxiosRequestConfig) {
        return PriceApiFp(this.configuration).apiPriceIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PriceUpdateDto} [priceUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceApi
     */
    public apiPriceIdPatch(id: number, priceUpdateDto?: PriceUpdateDto, options?: AxiosRequestConfig) {
        return PriceApiFp(this.configuration).apiPriceIdPatch(id, priceUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PriceUpdateDto} [priceUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceApi
     */
    public apiPriceIdPut(id: number, priceUpdateDto?: PriceUpdateDto, options?: AxiosRequestConfig) {
        return PriceApiFp(this.configuration).apiPriceIdPut(id, priceUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PriceCreateDto} [priceCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceApi
     */
    public apiPricePost(priceCreateDto?: PriceCreateDto, options?: AxiosRequestConfig) {
        return PriceApiFp(this.configuration).apiPricePost(priceCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductIdDelete', 'id', id)
            const localVarPath = `/api/Product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductIdGet', 'id', id)
            const localVarPath = `/api/Product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdPatch: async (id: number, productUpdateDto?: ProductUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductIdPatch', 'id', id)
            const localVarPath = `/api/Product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdPut: async (id: number, productUpdateDto?: ProductUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductIdPut', 'id', id)
            const localVarPath = `/api/Product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} categoryId 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPositionIdCategoryIdDirectionPatch: async (id: number, categoryId: number, direction: Direction, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductPositionIdCategoryIdDirectionPatch', 'id', id)
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('apiProductPositionIdCategoryIdDirectionPatch', 'categoryId', categoryId)
            // verify required parameter 'direction' is not null or undefined
            assertParamExists('apiProductPositionIdCategoryIdDirectionPatch', 'direction', direction)
            const localVarPath = `/api/Product/Position/{id}/{categoryId}/{direction}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)))
                .replace(`{${"direction"}}`, encodeURIComponent(String(direction)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductCreateDto} [productCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPost: async (productCreateDto?: ProductCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductProductCategoryLinksGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Product/ProductCategoryLinks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductProductIdSoftDeleteCategoryIdDelete: async (productId: number, categoryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('apiProductProductIdSoftDeleteCategoryIdDelete', 'productId', productId)
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('apiProductProductIdSoftDeleteCategoryIdDelete', 'categoryId', categoryId)
            const localVarPath = `/api/Product/{productId}/softDelete/{categoryId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductTagsUpdateDto} [productTagsUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductTagsPut: async (productTagsUpdateDto?: ProductTagsUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Product/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productTagsUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductIdPatch(id: number, productUpdateDto?: ProductUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductIdPatch(id, productUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductIdPut(id: number, productUpdateDto?: ProductUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductIdPut(id, productUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} categoryId 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductPositionIdCategoryIdDirectionPatch(id: number, categoryId: number, direction: Direction, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryProductDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductPositionIdCategoryIdDirectionPatch(id, categoryId, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductCreateDto} [productCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductPost(productCreateDto?: ProductCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductPost(productCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductProductCategoryLinksGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryProductDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductProductCategoryLinksGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} productId 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductProductIdSoftDeleteCategoryIdDelete(productId: number, categoryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductProductIdSoftDeleteCategoryIdDelete(productId, categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductTagsUpdateDto} [productTagsUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductTagsPut(productTagsUpdateDto?: ProductTagsUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductTagsPut(productTagsUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductGet(options?: any): AxiosPromise<Array<ProductDto>> {
            return localVarFp.apiProductGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdGet(id: number, options?: any): AxiosPromise<ProductDetailsDto> {
            return localVarFp.apiProductIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdPatch(id: number, productUpdateDto?: ProductUpdateDto, options?: any): AxiosPromise<ProductDetailsDto> {
            return localVarFp.apiProductIdPatch(id, productUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdPut(id: number, productUpdateDto?: ProductUpdateDto, options?: any): AxiosPromise<ProductDetailsDto> {
            return localVarFp.apiProductIdPut(id, productUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} categoryId 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPositionIdCategoryIdDirectionPatch(id: number, categoryId: number, direction: Direction, options?: any): AxiosPromise<Array<CategoryProductDto>> {
            return localVarFp.apiProductPositionIdCategoryIdDirectionPatch(id, categoryId, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductCreateDto} [productCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPost(productCreateDto?: ProductCreateDto, options?: any): AxiosPromise<ProductDetailsDto> {
            return localVarFp.apiProductPost(productCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductProductCategoryLinksGet(options?: any): AxiosPromise<Array<CategoryProductDto>> {
            return localVarFp.apiProductProductCategoryLinksGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productId 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductProductIdSoftDeleteCategoryIdDelete(productId: number, categoryId: number, options?: any): AxiosPromise<ProductDto> {
            return localVarFp.apiProductProductIdSoftDeleteCategoryIdDelete(productId, categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductTagsUpdateDto} [productTagsUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductTagsPut(productTagsUpdateDto?: ProductTagsUpdateDto, options?: any): AxiosPromise<ProductDetailsDto> {
            return localVarFp.apiProductTagsPut(productTagsUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductGet(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiProductGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductIdDelete(id: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiProductIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductIdGet(id: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiProductIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductUpdateDto} [productUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductIdPatch(id: number, productUpdateDto?: ProductUpdateDto, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiProductIdPatch(id, productUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductUpdateDto} [productUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductIdPut(id: number, productUpdateDto?: ProductUpdateDto, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiProductIdPut(id, productUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} categoryId 
     * @param {Direction} direction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductPositionIdCategoryIdDirectionPatch(id: number, categoryId: number, direction: Direction, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiProductPositionIdCategoryIdDirectionPatch(id, categoryId, direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductCreateDto} [productCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductPost(productCreateDto?: ProductCreateDto, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiProductPost(productCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductProductCategoryLinksGet(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiProductProductCategoryLinksGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productId 
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductProductIdSoftDeleteCategoryIdDelete(productId: number, categoryId: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiProductProductIdSoftDeleteCategoryIdDelete(productId, categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductTagsUpdateDto} [productTagsUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductTagsPut(productTagsUpdateDto?: ProductTagsUpdateDto, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiProductTagsPut(productTagsUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductDetailApi - axios parameter creator
 * @export
 */
export const ProductDetailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductDetailGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductDetailIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductDetailIdDelete', 'id', id)
            const localVarPath = `/api/ProductDetail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductDetailIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductDetailIdGet', 'id', id)
            const localVarPath = `/api/ProductDetail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductDetailUpdateDto} [productDetailUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductDetailIdPatch: async (id: number, productDetailUpdateDto?: ProductDetailUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductDetailIdPatch', 'id', id)
            const localVarPath = `/api/ProductDetail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productDetailUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductDetailUpdateDto} [productDetailUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductDetailIdPut: async (id: number, productDetailUpdateDto?: ProductDetailUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductDetailIdPut', 'id', id)
            const localVarPath = `/api/ProductDetail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productDetailUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductDetailCreateDto} [productDetailCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductDetailPost: async (productDetailCreateDto?: ProductDetailCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productDetailCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductDetailProductDetailsProductIdGet: async (productId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('apiProductDetailProductDetailsProductIdGet', 'productId', productId)
            const localVarPath = `/api/ProductDetail/productDetails/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductDetailApi - functional programming interface
 * @export
 */
export const ProductDetailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductDetailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductDetailGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductDetailDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductDetailGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductDetailIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductDetailIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductDetailIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductDetailIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductDetailUpdateDto} [productDetailUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductDetailIdPatch(id: number, productDetailUpdateDto?: ProductDetailUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductDetailIdPatch(id, productDetailUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductDetailUpdateDto} [productDetailUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductDetailIdPut(id: number, productDetailUpdateDto?: ProductDetailUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductDetailIdPut(id, productDetailUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductDetailCreateDto} [productDetailCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductDetailPost(productDetailCreateDto?: ProductDetailCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductDetailPost(productDetailCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductDetailProductDetailsProductIdGet(productId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductDetailDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductDetailProductDetailsProductIdGet(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductDetailApi - factory interface
 * @export
 */
export const ProductDetailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductDetailApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductDetailGet(options?: any): AxiosPromise<Array<ProductDetailDto>> {
            return localVarFp.apiProductDetailGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductDetailIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductDetailIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductDetailIdGet(id: number, options?: any): AxiosPromise<ProductDetailDetailsDto> {
            return localVarFp.apiProductDetailIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductDetailUpdateDto} [productDetailUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductDetailIdPatch(id: number, productDetailUpdateDto?: ProductDetailUpdateDto, options?: any): AxiosPromise<ProductDetailDetailsDto> {
            return localVarFp.apiProductDetailIdPatch(id, productDetailUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductDetailUpdateDto} [productDetailUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductDetailIdPut(id: number, productDetailUpdateDto?: ProductDetailUpdateDto, options?: any): AxiosPromise<ProductDetailDetailsDto> {
            return localVarFp.apiProductDetailIdPut(id, productDetailUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductDetailCreateDto} [productDetailCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductDetailPost(productDetailCreateDto?: ProductDetailCreateDto, options?: any): AxiosPromise<ProductDetailDetailsDto> {
            return localVarFp.apiProductDetailPost(productDetailCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductDetailProductDetailsProductIdGet(productId: number, options?: any): AxiosPromise<Array<ProductDetailDto>> {
            return localVarFp.apiProductDetailProductDetailsProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductDetailApi - object-oriented interface
 * @export
 * @class ProductDetailApi
 * @extends {BaseAPI}
 */
export class ProductDetailApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductDetailApi
     */
    public apiProductDetailGet(options?: AxiosRequestConfig) {
        return ProductDetailApiFp(this.configuration).apiProductDetailGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductDetailApi
     */
    public apiProductDetailIdDelete(id: number, options?: AxiosRequestConfig) {
        return ProductDetailApiFp(this.configuration).apiProductDetailIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductDetailApi
     */
    public apiProductDetailIdGet(id: number, options?: AxiosRequestConfig) {
        return ProductDetailApiFp(this.configuration).apiProductDetailIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductDetailUpdateDto} [productDetailUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductDetailApi
     */
    public apiProductDetailIdPatch(id: number, productDetailUpdateDto?: ProductDetailUpdateDto, options?: AxiosRequestConfig) {
        return ProductDetailApiFp(this.configuration).apiProductDetailIdPatch(id, productDetailUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductDetailUpdateDto} [productDetailUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductDetailApi
     */
    public apiProductDetailIdPut(id: number, productDetailUpdateDto?: ProductDetailUpdateDto, options?: AxiosRequestConfig) {
        return ProductDetailApiFp(this.configuration).apiProductDetailIdPut(id, productDetailUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductDetailCreateDto} [productDetailCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductDetailApi
     */
    public apiProductDetailPost(productDetailCreateDto?: ProductDetailCreateDto, options?: AxiosRequestConfig) {
        return ProductDetailApiFp(this.configuration).apiProductDetailPost(productDetailCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductDetailApi
     */
    public apiProductDetailProductDetailsProductIdGet(productId: number, options?: AxiosRequestConfig) {
        return ProductDetailApiFp(this.configuration).apiProductDetailProductDetailsProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductImageApi - axios parameter creator
 * @export
 */
export const ProductImageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductImageGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductImageIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductImageIdDelete', 'id', id)
            const localVarPath = `/api/ProductImage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductImageIdFileStreamGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductImageIdFileStreamGet', 'id', id)
            const localVarPath = `/api/ProductImage/{id}/FileStream`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductImageIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductImageIdGet', 'id', id)
            const localVarPath = `/api/ProductImage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductImageUpdateDto} [productImageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductImageIdPatch: async (id: number, productImageUpdateDto?: ProductImageUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductImageIdPatch', 'id', id)
            const localVarPath = `/api/ProductImage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productImageUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductImageUpdateDto} [productImageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductImageIdPut: async (id: number, productImageUpdateDto?: ProductImageUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductImageIdPut', 'id', id)
            const localVarPath = `/api/ProductImage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productImageUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [position] 
         * @param {File} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductImagePost: async (position?: number, file?: File, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (position !== undefined) { 
                localVarFormParams.append('Position', position as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductImageProductImagesProductIdGet: async (productId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('apiProductImageProductImagesProductIdGet', 'productId', productId)
            const localVarPath = `/api/ProductImage/ProductImages/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductImageApi - functional programming interface
 * @export
 */
export const ProductImageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductImageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductImageGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductImageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductImageGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductImageIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductImageIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductImageIdFileStreamGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductImageIdFileStreamGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductImageIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductImageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductImageIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductImageUpdateDto} [productImageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductImageIdPatch(id: number, productImageUpdateDto?: ProductImageUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductImageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductImageIdPatch(id, productImageUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductImageUpdateDto} [productImageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductImageIdPut(id: number, productImageUpdateDto?: ProductImageUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductImageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductImageIdPut(id, productImageUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [position] 
         * @param {File} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductImagePost(position?: number, file?: File, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductImageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductImagePost(position, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductImageProductImagesProductIdGet(productId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductImageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductImageProductImagesProductIdGet(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductImageApi - factory interface
 * @export
 */
export const ProductImageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductImageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductImageGet(options?: any): AxiosPromise<Array<ProductImageDto>> {
            return localVarFp.apiProductImageGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductImageIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductImageIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductImageIdFileStreamGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductImageIdFileStreamGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductImageIdGet(id: number, options?: any): AxiosPromise<ProductImageDetailsDto> {
            return localVarFp.apiProductImageIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductImageUpdateDto} [productImageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductImageIdPatch(id: number, productImageUpdateDto?: ProductImageUpdateDto, options?: any): AxiosPromise<ProductImageDetailsDto> {
            return localVarFp.apiProductImageIdPatch(id, productImageUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductImageUpdateDto} [productImageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductImageIdPut(id: number, productImageUpdateDto?: ProductImageUpdateDto, options?: any): AxiosPromise<ProductImageDetailsDto> {
            return localVarFp.apiProductImageIdPut(id, productImageUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [position] 
         * @param {File} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductImagePost(position?: number, file?: File, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<ProductImageDetailsDto> {
            return localVarFp.apiProductImagePost(position, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductImageProductImagesProductIdGet(productId: number, options?: any): AxiosPromise<Array<ProductImageDto>> {
            return localVarFp.apiProductImageProductImagesProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductImageApi - object-oriented interface
 * @export
 * @class ProductImageApi
 * @extends {BaseAPI}
 */
export class ProductImageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductImageApi
     */
    public apiProductImageGet(options?: AxiosRequestConfig) {
        return ProductImageApiFp(this.configuration).apiProductImageGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductImageApi
     */
    public apiProductImageIdDelete(id: number, options?: AxiosRequestConfig) {
        return ProductImageApiFp(this.configuration).apiProductImageIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductImageApi
     */
    public apiProductImageIdFileStreamGet(id: number, options?: AxiosRequestConfig) {
        return ProductImageApiFp(this.configuration).apiProductImageIdFileStreamGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductImageApi
     */
    public apiProductImageIdGet(id: number, options?: AxiosRequestConfig) {
        return ProductImageApiFp(this.configuration).apiProductImageIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductImageUpdateDto} [productImageUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductImageApi
     */
    public apiProductImageIdPatch(id: number, productImageUpdateDto?: ProductImageUpdateDto, options?: AxiosRequestConfig) {
        return ProductImageApiFp(this.configuration).apiProductImageIdPatch(id, productImageUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductImageUpdateDto} [productImageUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductImageApi
     */
    public apiProductImageIdPut(id: number, productImageUpdateDto?: ProductImageUpdateDto, options?: AxiosRequestConfig) {
        return ProductImageApiFp(this.configuration).apiProductImageIdPut(id, productImageUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [position] 
     * @param {File} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductImageApi
     */
    public apiProductImagePost(position?: number, file?: File, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: AxiosRequestConfig) {
        return ProductImageApiFp(this.configuration).apiProductImagePost(position, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductImageApi
     */
    public apiProductImageProductImagesProductIdGet(productId: number, options?: AxiosRequestConfig) {
        return ProductImageApiFp(this.configuration).apiProductImageProductImagesProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductPriceApi - axios parameter creator
 * @export
 */
export const ProductPriceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPriceGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductPrice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPriceIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductPriceIdDelete', 'id', id)
            const localVarPath = `/api/ProductPrice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPriceIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductPriceIdGet', 'id', id)
            const localVarPath = `/api/ProductPrice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductPriceUpdateDto} [productPriceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPriceIdPatch: async (id: number, productPriceUpdateDto?: ProductPriceUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductPriceIdPatch', 'id', id)
            const localVarPath = `/api/ProductPrice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productPriceUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductPriceUpdateDto} [productPriceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPriceIdPut: async (id: number, productPriceUpdateDto?: ProductPriceUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductPriceIdPut', 'id', id)
            const localVarPath = `/api/ProductPrice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productPriceUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductPriceCreateDto} [productPriceCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPricePost: async (productPriceCreateDto?: ProductPriceCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductPrice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productPriceCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPriceProductPricesProductIdGet: async (productId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('apiProductPriceProductPricesProductIdGet', 'productId', productId)
            const localVarPath = `/api/ProductPrice/productPrices/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPriceProductsPricesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductPrice/productsPrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductPriceApi - functional programming interface
 * @export
 */
export const ProductPriceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductPriceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductPriceGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductPriceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductPriceGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductPriceIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductPriceIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductPriceIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPriceDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductPriceIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductPriceUpdateDto} [productPriceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductPriceIdPatch(id: number, productPriceUpdateDto?: ProductPriceUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPriceDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductPriceIdPatch(id, productPriceUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductPriceUpdateDto} [productPriceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductPriceIdPut(id: number, productPriceUpdateDto?: ProductPriceUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPriceDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductPriceIdPut(id, productPriceUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductPriceCreateDto} [productPriceCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductPricePost(productPriceCreateDto?: ProductPriceCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPriceDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductPricePost(productPriceCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductPriceProductPricesProductIdGet(productId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductPriceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductPriceProductPricesProductIdGet(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductPriceProductsPricesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductPriceForShopDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductPriceProductsPricesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductPriceApi - factory interface
 * @export
 */
export const ProductPriceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductPriceApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPriceGet(options?: any): AxiosPromise<Array<ProductPriceDto>> {
            return localVarFp.apiProductPriceGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPriceIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductPriceIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPriceIdGet(id: number, options?: any): AxiosPromise<ProductPriceDetailsDto> {
            return localVarFp.apiProductPriceIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductPriceUpdateDto} [productPriceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPriceIdPatch(id: number, productPriceUpdateDto?: ProductPriceUpdateDto, options?: any): AxiosPromise<ProductPriceDetailsDto> {
            return localVarFp.apiProductPriceIdPatch(id, productPriceUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductPriceUpdateDto} [productPriceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPriceIdPut(id: number, productPriceUpdateDto?: ProductPriceUpdateDto, options?: any): AxiosPromise<ProductPriceDetailsDto> {
            return localVarFp.apiProductPriceIdPut(id, productPriceUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductPriceCreateDto} [productPriceCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPricePost(productPriceCreateDto?: ProductPriceCreateDto, options?: any): AxiosPromise<ProductPriceDetailsDto> {
            return localVarFp.apiProductPricePost(productPriceCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPriceProductPricesProductIdGet(productId: number, options?: any): AxiosPromise<Array<ProductPriceDto>> {
            return localVarFp.apiProductPriceProductPricesProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPriceProductsPricesGet(options?: any): AxiosPromise<Array<ProductPriceForShopDto>> {
            return localVarFp.apiProductPriceProductsPricesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductPriceApi - object-oriented interface
 * @export
 * @class ProductPriceApi
 * @extends {BaseAPI}
 */
export class ProductPriceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPriceApi
     */
    public apiProductPriceGet(options?: AxiosRequestConfig) {
        return ProductPriceApiFp(this.configuration).apiProductPriceGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPriceApi
     */
    public apiProductPriceIdDelete(id: number, options?: AxiosRequestConfig) {
        return ProductPriceApiFp(this.configuration).apiProductPriceIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPriceApi
     */
    public apiProductPriceIdGet(id: number, options?: AxiosRequestConfig) {
        return ProductPriceApiFp(this.configuration).apiProductPriceIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductPriceUpdateDto} [productPriceUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPriceApi
     */
    public apiProductPriceIdPatch(id: number, productPriceUpdateDto?: ProductPriceUpdateDto, options?: AxiosRequestConfig) {
        return ProductPriceApiFp(this.configuration).apiProductPriceIdPatch(id, productPriceUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductPriceUpdateDto} [productPriceUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPriceApi
     */
    public apiProductPriceIdPut(id: number, productPriceUpdateDto?: ProductPriceUpdateDto, options?: AxiosRequestConfig) {
        return ProductPriceApiFp(this.configuration).apiProductPriceIdPut(id, productPriceUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductPriceCreateDto} [productPriceCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPriceApi
     */
    public apiProductPricePost(productPriceCreateDto?: ProductPriceCreateDto, options?: AxiosRequestConfig) {
        return ProductPriceApiFp(this.configuration).apiProductPricePost(productPriceCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPriceApi
     */
    public apiProductPriceProductPricesProductIdGet(productId: number, options?: AxiosRequestConfig) {
        return ProductPriceApiFp(this.configuration).apiProductPriceProductPricesProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPriceApi
     */
    public apiProductPriceProductsPricesGet(options?: AxiosRequestConfig) {
        return ProductPriceApiFp(this.configuration).apiProductPriceProductsPricesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductStatisticsApi - axios parameter creator
 * @export
 */
export const ProductStatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductStatisticsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductStatistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductStatisticsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductStatisticsIdDelete', 'id', id)
            const localVarPath = `/api/ProductStatistics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductStatisticsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductStatisticsIdGet', 'id', id)
            const localVarPath = `/api/ProductStatistics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductStatisticsUpdateDto} [productStatisticsUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductStatisticsIdPatch: async (id: number, productStatisticsUpdateDto?: ProductStatisticsUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductStatisticsIdPatch', 'id', id)
            const localVarPath = `/api/ProductStatistics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productStatisticsUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductStatisticsUpdateDto} [productStatisticsUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductStatisticsIdPut: async (id: number, productStatisticsUpdateDto?: ProductStatisticsUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductStatisticsIdPut', 'id', id)
            const localVarPath = `/api/ProductStatistics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productStatisticsUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductStatisticsCreateDto} [productStatisticsCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductStatisticsPost: async (productStatisticsCreateDto?: ProductStatisticsCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductStatistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productStatisticsCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductStatisticsProductStatisticsProductIdGet: async (productId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('apiProductStatisticsProductStatisticsProductIdGet', 'productId', productId)
            const localVarPath = `/api/ProductStatistics/productStatistics/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductStatisticsApi - functional programming interface
 * @export
 */
export const ProductStatisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductStatisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductStatisticsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductStatisticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductStatisticsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductStatisticsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductStatisticsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductStatisticsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductStatisticsDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductStatisticsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductStatisticsUpdateDto} [productStatisticsUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductStatisticsIdPatch(id: number, productStatisticsUpdateDto?: ProductStatisticsUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductStatisticsDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductStatisticsIdPatch(id, productStatisticsUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductStatisticsUpdateDto} [productStatisticsUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductStatisticsIdPut(id: number, productStatisticsUpdateDto?: ProductStatisticsUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductStatisticsDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductStatisticsIdPut(id, productStatisticsUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductStatisticsCreateDto} [productStatisticsCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductStatisticsPost(productStatisticsCreateDto?: ProductStatisticsCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductStatisticsDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductStatisticsPost(productStatisticsCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductStatisticsProductStatisticsProductIdGet(productId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductStatisticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductStatisticsProductStatisticsProductIdGet(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductStatisticsApi - factory interface
 * @export
 */
export const ProductStatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductStatisticsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductStatisticsGet(options?: any): AxiosPromise<Array<ProductStatisticsDto>> {
            return localVarFp.apiProductStatisticsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductStatisticsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductStatisticsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductStatisticsIdGet(id: number, options?: any): AxiosPromise<ProductStatisticsDetailsDto> {
            return localVarFp.apiProductStatisticsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductStatisticsUpdateDto} [productStatisticsUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductStatisticsIdPatch(id: number, productStatisticsUpdateDto?: ProductStatisticsUpdateDto, options?: any): AxiosPromise<ProductStatisticsDetailsDto> {
            return localVarFp.apiProductStatisticsIdPatch(id, productStatisticsUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductStatisticsUpdateDto} [productStatisticsUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductStatisticsIdPut(id: number, productStatisticsUpdateDto?: ProductStatisticsUpdateDto, options?: any): AxiosPromise<ProductStatisticsDetailsDto> {
            return localVarFp.apiProductStatisticsIdPut(id, productStatisticsUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductStatisticsCreateDto} [productStatisticsCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductStatisticsPost(productStatisticsCreateDto?: ProductStatisticsCreateDto, options?: any): AxiosPromise<ProductStatisticsDetailsDto> {
            return localVarFp.apiProductStatisticsPost(productStatisticsCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductStatisticsProductStatisticsProductIdGet(productId: number, options?: any): AxiosPromise<Array<ProductStatisticsDto>> {
            return localVarFp.apiProductStatisticsProductStatisticsProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductStatisticsApi - object-oriented interface
 * @export
 * @class ProductStatisticsApi
 * @extends {BaseAPI}
 */
export class ProductStatisticsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStatisticsApi
     */
    public apiProductStatisticsGet(options?: AxiosRequestConfig) {
        return ProductStatisticsApiFp(this.configuration).apiProductStatisticsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStatisticsApi
     */
    public apiProductStatisticsIdDelete(id: number, options?: AxiosRequestConfig) {
        return ProductStatisticsApiFp(this.configuration).apiProductStatisticsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStatisticsApi
     */
    public apiProductStatisticsIdGet(id: number, options?: AxiosRequestConfig) {
        return ProductStatisticsApiFp(this.configuration).apiProductStatisticsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductStatisticsUpdateDto} [productStatisticsUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStatisticsApi
     */
    public apiProductStatisticsIdPatch(id: number, productStatisticsUpdateDto?: ProductStatisticsUpdateDto, options?: AxiosRequestConfig) {
        return ProductStatisticsApiFp(this.configuration).apiProductStatisticsIdPatch(id, productStatisticsUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductStatisticsUpdateDto} [productStatisticsUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStatisticsApi
     */
    public apiProductStatisticsIdPut(id: number, productStatisticsUpdateDto?: ProductStatisticsUpdateDto, options?: AxiosRequestConfig) {
        return ProductStatisticsApiFp(this.configuration).apiProductStatisticsIdPut(id, productStatisticsUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductStatisticsCreateDto} [productStatisticsCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStatisticsApi
     */
    public apiProductStatisticsPost(productStatisticsCreateDto?: ProductStatisticsCreateDto, options?: AxiosRequestConfig) {
        return ProductStatisticsApiFp(this.configuration).apiProductStatisticsPost(productStatisticsCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStatisticsApi
     */
    public apiProductStatisticsProductStatisticsProductIdGet(productId: number, options?: AxiosRequestConfig) {
        return ProductStatisticsApiFp(this.configuration).apiProductStatisticsProductStatisticsProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShinefarApi - axios parameter creator
 * @export
 */
export const ShinefarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ShinefarContactDto} [shinefarContactDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiShinefarContactPost: async (shinefarContactDto?: ShinefarContactDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Shinefar/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shinefarContactDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShinefarApi - functional programming interface
 * @export
 */
export const ShinefarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShinefarApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ShinefarContactDto} [shinefarContactDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiShinefarContactPost(shinefarContactDto?: ShinefarContactDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiShinefarContactPost(shinefarContactDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShinefarApi - factory interface
 * @export
 */
export const ShinefarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShinefarApiFp(configuration)
    return {
        /**
         * 
         * @param {ShinefarContactDto} [shinefarContactDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiShinefarContactPost(shinefarContactDto?: ShinefarContactDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiShinefarContactPost(shinefarContactDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShinefarApi - object-oriented interface
 * @export
 * @class ShinefarApi
 * @extends {BaseAPI}
 */
export class ShinefarApi extends BaseAPI {
    /**
     * 
     * @param {ShinefarContactDto} [shinefarContactDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShinefarApi
     */
    public apiShinefarContactPost(shinefarContactDto?: ShinefarContactDto, options?: AxiosRequestConfig) {
        return ShinefarApiFp(this.configuration).apiShinefarContactPost(shinefarContactDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TagApi - axios parameter creator
 * @export
 */
export const TagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Tag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTagIdDelete', 'id', id)
            const localVarPath = `/api/Tag/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTagIdGet', 'id', id)
            const localVarPath = `/api/Tag/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TagUpdateDto} [tagUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagIdPatch: async (id: number, tagUpdateDto?: TagUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTagIdPatch', 'id', id)
            const localVarPath = `/api/Tag/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TagUpdateDto} [tagUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagIdPut: async (id: number, tagUpdateDto?: TagUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTagIdPut', 'id', id)
            const localVarPath = `/api/Tag/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TagCreateDto} [tagCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagPost: async (tagCreateDto?: TagCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Tag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagTagsProductIdGet: async (productId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('apiTagTagsProductIdGet', 'productId', productId)
            const localVarPath = `/api/Tag/tags/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagApi - functional programming interface
 * @export
 */
export const TagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTagGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTagGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTagIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTagIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTagIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTagIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TagUpdateDto} [tagUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTagIdPatch(id: number, tagUpdateDto?: TagUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTagIdPatch(id, tagUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TagUpdateDto} [tagUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTagIdPut(id: number, tagUpdateDto?: TagUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTagIdPut(id, tagUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TagCreateDto} [tagCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTagPost(tagCreateDto?: TagCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTagPost(tagCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTagTagsProductIdGet(productId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTagTagsProductIdGet(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TagApi - factory interface
 * @export
 */
export const TagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagGet(options?: any): AxiosPromise<Array<TagDto>> {
            return localVarFp.apiTagGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiTagIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagIdGet(id: number, options?: any): AxiosPromise<TagDetailsDto> {
            return localVarFp.apiTagIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TagUpdateDto} [tagUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagIdPatch(id: number, tagUpdateDto?: TagUpdateDto, options?: any): AxiosPromise<TagDetailsDto> {
            return localVarFp.apiTagIdPatch(id, tagUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TagUpdateDto} [tagUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagIdPut(id: number, tagUpdateDto?: TagUpdateDto, options?: any): AxiosPromise<TagDetailsDto> {
            return localVarFp.apiTagIdPut(id, tagUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TagCreateDto} [tagCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagPost(tagCreateDto?: TagCreateDto, options?: any): AxiosPromise<TagDetailsDto> {
            return localVarFp.apiTagPost(tagCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagTagsProductIdGet(productId: number, options?: any): AxiosPromise<Array<TagDto>> {
            return localVarFp.apiTagTagsProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagApi - object-oriented interface
 * @export
 * @class TagApi
 * @extends {BaseAPI}
 */
export class TagApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiTagGet(options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiTagGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiTagIdDelete(id: number, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiTagIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiTagIdGet(id: number, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiTagIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TagUpdateDto} [tagUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiTagIdPatch(id: number, tagUpdateDto?: TagUpdateDto, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiTagIdPatch(id, tagUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TagUpdateDto} [tagUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiTagIdPut(id: number, tagUpdateDto?: TagUpdateDto, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiTagIdPut(id, tagUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TagCreateDto} [tagCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiTagPost(tagCreateDto?: TagCreateDto, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiTagPost(tagCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiTagTagsProductIdGet(productId: number, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiTagTagsProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }
}


